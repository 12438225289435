<template>
  <div class="dropdown-menu">
    <div class="basin-title">
      {{ $t('header.selectBasin') }}
    </div>
    <div class="sub-basins">
      <div
        class="sub-basin"
        v-for="basin in reactiveDataSubbasins"
        :key="basin.name"
        @click="cbSelectSubbasins(basin)"
      >
        <div class="title-basin">
          <div class="name" v-if="basin.name !== 'Вся Україна'">{{ basin.name }}</div>
          <div class="name" v-if="basin.name == 'Вся Україна'" id="ukrBtn">{{ basin.name }}</div>
          <div
            class="arrow"
            :class="{
              opened: basin.isOpened
            }"
          >
            <img v-if="basin.name !== 'Вся Україна'" src="./image/arrow-low.png" alt="arrow" />
          </div>
        </div>

        <template v-if="basin.isOpened">
          <div class="river-list" @click.stop>
            <div
              class="river"
              v-for="river in basin.rivers"
              :key="river.name"
              @click.stop="selectRiver(river, basin)"
            >
              {{ river.name }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { dataSubbasins } from '@/UI/Header/components/BasinDropDown/ChildData'
import { useGlobalStore } from '@/stores/globalStore'
import { subbasinsWithCodes } from '@/utils/subbasinsWithCodes'
import { nextTick, reactive, onMounted } from 'vue'
const globalStore = useGlobalStore()

const reactiveDataSubbasins = reactive(dataSubbasins.map((subbasin) => ({ ...subbasin })))

function getBasinCode(basinName) {
  return subbasinsWithCodes[basinName]
}

const selectSubbasin = async (subbasinObject) => {
  globalStore.setSelectedBasin(subbasinObject.name)
  globalStore.setSelectedBasinCode(getBasinCode(subbasinObject.name))
  globalStore.setSelectedBasinChildren(subbasinObject.children)
  globalStore.setLogoBlockInfo(subbasinObject.logoBlock)
  globalStore.setSelectedBigBasinCode(getBasinCode(subbasinObject.name))
  globalStore.setSelectedBasinRivers(subbasinObject.rivers)
}

const cbSelectSubbasins = async (subbasinObject) => {
  selectSubbasin(subbasinObject)

  if (subbasinObject.name !== 'Вся Україна') {
    subbasinObject.isOpened = !subbasinObject.isOpened
    await nextTick()
  }
}

const selectRiver = (riverObject, subbasinObject) => {
  selectSubbasin(subbasinObject)

  globalStore.setSelectedBasin(riverObject.name)
  globalStore.setSelectedBasinCode(riverObject.code)
}

// Fixing vue 3 reactivity
onMounted(() => {
  if (globalStore.firstLoad) {
    const ukrBtn = document.getElementById('ukrBtn')
    ukrBtn.click()
    globalStore.firstLoad = false
  }
})
</script>
<style scoped lang="scss">
.arrow.opened {
  transform: rotate(0deg);
}

.dropdown-menu {
  position: absolute;
  overflow-y: scroll;
  right: 0;
  top: 60px;
  width: 548px;
  max-height: 800px;
  border-radius: 8px;
  background-color: var(--color-white);
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.15);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-dark-gray);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-white);
    padding: 0 5px;
  }

  .basin-title {
    padding-left: 24px;
    color: var(--color-secondary-blue);
    font-size: 15px;
    margin-top: 42px;
    margin-bottom: 15px;
    font-weight: 600;
  }

  .sub-basin {
    font-size: 20px;
    padding-right: 16px;
    padding-top: 13px;
    padding-bottom: 11px;
    font-weight: 600;
  }

  .river-list {
    background-color: var(--color-light-gray);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08) inset;
    padding-left: 24px;
    margin-top: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;

    .river {
      margin: 13px 0;
      cursor: pointer;
    }
  }

  .title-basin {
    padding-left: 24px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    img {
      margin-right: 32px;
    }

    .opened img {
      transform: rotate(90deg);
    }
  }
}
</style>
