export const dataSubbasins = [
  {
    name: 'Вся Україна',
    children: [
      {
        name: 'Дніпро',
        runoff: { value: 17, directionTop: false },
        temperature: { value: 0.6, directionTop: true },
        precipitation: { value: 1, directionTop: false }
      },
      {
        name: "Прип'ять",
        runoff: { value: 16, directionTop: false },
        temperature: { value: 0.6, directionTop: true },
        precipitation: { value: 1, directionTop: false }
      },
      {
        name: 'Десна',
        runoff: { value: 23, directionTop: false },
        temperature: { value: 0.6, directionTop: true },
        precipitation: { value: 1, directionTop: false }
      },
      {
        name: 'Пів.Буг',
        runoff: { value: 40, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 6, directionTop: false }
      },
      {
        name: 'Сів.Донець',
        runoff: { value: 31, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 4, directionTop: false }
      },
      {
        name: 'Дністер',
        runoff: { value: 21, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 6, directionTop: false }
      },
      {
        name: 'Західний Буг',
        runoff: { value: 8, directionTop: false },
        temperature: { value: 0.6, directionTop: true },
        precipitation: { value: 0, directionTop: true }
      },
      {
        name: 'Псел',
        runoff: { value: 35, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 1, directionTop: false }
      },
      {
        name: 'Самара',
        runoff: { value: 36, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 4, directionTop: false }
      },
      {
        name: 'Сула',
        runoff: { value: 49, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 1, directionTop: false }
      }
    ],
    logoBlock: {
      runoff: { value: -19, directionTop: false },
      temperature: { value: 0.7, directionTop: true },
      precipitation: { value: -2, directionTop: false }
    },
    isOpened: false
  },
  {
    name: 'Суб-басейн Верхнього Дніпра',
    rivers: [
      {
        name: 'р. Дніпро від державного кордону до початку Київського водосховища (включаючи р. Сож у межах України)',
        code: 'M5.1.1.01'
      }
    ],
    children: [
      {
        name: 'Верх.Дніпро',
        runoff: { value: 1, directionTop: false },
        temperature: { value: 0.5, directionTop: true },
        precipitation: { value: 2, directionTop: true }
      },
      {
        name: 'Сож',
        runoff: { value: 3, directionTop: false },
        temperature: { value: 0.5, directionTop: true },
        precipitation: { value: 2, directionTop: true }
      }
    ],
    logoBlock: {
      runoff: { value: -1, directionTop: false },
      temperature: { value: 0.5, directionTop: true },
      precipitation: { value: 2, directionTop: false }
    },
    isOpened: false
  },
  {
    name: 'Суб-басейн Середнього Дніпра',
    rivers: [
      {
        name: 'Київське водосховище (включаючи р. Брагінка у межах України, виключаючи рр. Прип’ять, Тетерів, Ірпінь)',
        code: 'M5.1.2.02'
      },
      {
        name: 'р. Дніпро від греблі Київського водосховища до греблі Канівського водосховища (виключаючи рр. Десна, Трубіж)',
        code: 'M5.1.2.03'
      },
      {
        name: 'р. Дніпро від греблі Канівського водосховища до греблі Кременчуцького водосховища (виключаючи рр. Рось, Супій, Сула, Тясмин)',
        code: 'M5.1.2.04'
      },
      {
        name: 'р. Тетерів від витоку до г/п Житомир',
        code: 'M5.1.2.05'
      },
      {
        name: 'р. Тетерів від г/п Житомир до гирла р. Ірша (включаючи р. Ірша)',
        code: 'M5.1.2.06'
      },
      {
        name: 'р. Тетерів від гирла р. Ірша до гирла',
        code: 'M5.1.2.07'
      },
      {
        name: 'р. Ірпінь',
        code: 'M5.1.2.08'
      },
      {
        name: 'р. Трубіж',
        code: 'M5.1.2.09'
      },
      {
        name: 'р. Рось від витоку до кордону Київської та Черкаської областей',
        code: 'M5.1.2.10'
      },
      {
        name: 'р. Рось від кордону Київської та Черкаської областей до гирла',
        code: 'M5.1.2.11'
      },
      {
        name: 'р. Супій',
        code: 'M5.1.2.12'
      },
      {
        name: 'р. Сула від витоку до кордону Сумської та Полтавської областей',
        code: 'M5.1.2.13'
      },
      {
        name: 'р. Сула від кордону Сумської та Полтавської областей до г/п Лубни (виключаючи р. Удай)',
        code: 'M5.1.2.14'
      },
      {
        name: 'р. Сула від г/п Лубни до гирла',
        code: 'M5.1.2.15'
      },
      {
        name: 'р. Удай',
        code: 'M5.1.2.16'
      },
      {
        name: 'р. Тясмин',
        code: 'M5.1.2.17'
      },
      {
        name: 'р. Псел від державного кордону до кордону Сумської та Полтавської областей',
        code: 'M5.1.2.18'
      },
      {
        name: 'р. Псел від кордону Сумської та Полтавської областей до гирла р. Хорол',
        code: 'M5.1.2.19'
      },
      {
        name: 'р. Псел від гирла р. Хорол до гирла (виключаючи р. Хорол)',
        code: 'M5.1.2.20'
      },
      {
        name: 'р. Хорол',
        code: 'M5.1.2.21'
      },
      {
        name: 'р. Ворскла від державного кордону до кордону Сумської та Полтавської областей',
        code: 'M5.1.2.22'
      },
      {
        name: 'р. Ворскла від кордону Сумської та Полтавської областей до гирла',
        code: 'M5.1.2.23'
      },
      {
        name: 'р. Дніпро від греблі Кременчуцького водосховища до греблі Дніпродзержинського водосховища',
        code: 'M5.1.2.24'
      }
    ],
    children: [
      {
        name: 'Псел',
        runoff: { value: 35, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 1, directionTop: false }
      },
      {
        name: 'Сула',
        runoff: { value: 49, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 1, directionTop: false }
      },
      {
        name: 'Тетерів',
        runoff: { value: 40, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 5, directionTop: false }
      },
      {
        name: 'Ворскла',
        runoff: { value: 31, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 1, directionTop: false }
      },
      {
        name: 'Рось',
        runoff: { value: 44, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 6, directionTop: false }
      },
      {
        name: 'Удай',
        runoff: { value: 53, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 2, directionTop: false }
      },
      {
        name: 'Тясмин',
        runoff: { value: 30, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 4, directionTop: false }
      },
      {
        name: 'Трубіж',
        runoff: { value: 48, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 4, directionTop: false }
      },
      {
        name: 'Хорол',
        runoff: { value: 39, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 1, directionTop: false }
      },
      {
        name: 'Ірпінь',
        runoff: { value: 41, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 6, directionTop: false }
      },
      {
        name: 'Супій',
        runoff: { value: 71, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 4, directionTop: false }
      }
    ],
    logoBlock: {
      runoff: { value: 39, directionTop: false },
      temperature: { value: 0.7, directionTop: true },
      precipitation: { value: 3, directionTop: false }
    },
    isOpened: false
  },
  {
    name: 'Суб-басейн Нижнього Дніпра',
    rivers: [
      {
        name: 'р. Дніпро від греблі Дніпродзержинського водосховища до греблі Дніпровського водосховища (виключаючи рр. Оріль, Самара)',
        code: 'M5.1.3.25'
      },
      {
        name: 'р. Дніпро від греблі Дніпровського водосховища до греблі Каховського водосховища',
        code: 'M5.1.3.26'
      },
      {
        name: 'р. Дніпро від греблі Каховського водосховища до гирла (виключаючи р. Інгулець)',
        code: 'M5.1.3.27'
      },
      {
        name: 'р. Оріль від витоку до кордону Харківської та Дніпропетровської областей',
        code: 'M5.1.3.28'
      },
      {
        name: 'р. Оріль від кордону Харківської та Дніпропетровської областей до гирла',
        code: 'M5.1.3.29'
      },
      {
        name: 'р. Самара від витоку до гирла р. Вовча',
        code: 'M5.1.3.30'
      },
      {
        name: 'р. Самара від гирла р. Вовча до гирла (виключаючи р. Вовча)',
        code: 'M5.1.3.31'
      },
      {
        name: 'р. Вовча (виключаючи рр. Мокрі Яли, Гайчур)',
        code: 'M5.1.3.32'
      },
      {
        name: 'р. Мокрі Яли',
        code: 'M5.1.3.33'
      },
      {
        name: 'р. Гайчур',
        code: 'M5.1.3.34'
      },
      {
        name: 'р. Інгулець від витоку до кордону Кіровоградської та Дніпропетровської областей',
        code: 'M5.1.3.35'
      },
      {
        name: 'р. Інгулець від кордону Кіровоградської та Дніпропетровської областей до кордону Дніпропетровської та Херсонської областей (виключаючи)',
        code: 'M5.1.3.36'
      },
      {
        name: 'р. Інгулець від кордону Дніпропетровської та Херсонської областей до гирла',
        code: 'M5.1.3.37'
      },
      {
        name: 'р. Саксагань',
        code: 'M5.1.3.38'
      },
      {
        name: 'Дніпровський лиман',
        code: 'M5.1.3.39'
      }
    ],
    children: [
      {
        name: 'Самара',
        runoff: { value: 36, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 4, directionTop: false }
      },
      {
        name: 'Інгулець',
        runoff: { value: 13, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 0, directionTop: true }
      },
      {
        name: 'Вовча',
        runoff: { value: 32, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 4, directionTop: false }
      },
      {
        name: 'Оріль',
        runoff: { value: 44, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 2, directionTop: false }
      },
      {
        name: 'Базавлук',
        runoff: { value: 13, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 1, directionTop: true }
      },
      {
        name: 'Мокра Сура',
        runoff: { value: 18, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 0, directionTop: true }
      },
      {
        name: 'Мокрі Яли',
        runoff: { value: 34, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 4, directionTop: false }
      },
      {
        name: 'Конка',
        runoff: { value: 28, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 4, directionTop: false }
      },
      {
        name: 'Гайчур',
        runoff: { value: 34, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 5, directionTop: false }
      },
      {
        name: 'Саксагань',
        runoff: { value: 14, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 0, directionTop: true }
      }
    ],
    logoBlock: {
      runoff: { value: 27, directionTop: false },
      temperature: { value: 0.8, directionTop: true },
      precipitation: { value: 1, directionTop: false }
    },
    isOpened: false
  },
  {
    name: 'Суб-басейн Десни',
    rivers: [
      {
        name: 'р. Десна від державного кордону до гирла р. Сейм',
        code: 'M5.1.5.53'
      },
      {
        name: 'р. Десна від гирла р. Сейм до г/п Чернігів (виключаючи рр. Сейм, Снов)',
        code: 'M5.1.5.54'
      },
      {
        name: 'р. Десна від г/п Чернігів до гирла (виключаючи р. Остер)',
        code: 'M5.1.5.55'
      },
      {
        name: 'р. Сейм від державного кордону до г/п Мутин',
        code: 'M5.1.5.56'
      },
      {
        name: 'р. Сейм від г/п Мутин до гирла',
        code: 'M5.1.5.57'
      },
      {
        name: 'р. Снов',
        code: 'M5.1.5.58'
      },
      {
        name: 'р. Остер',
        code: 'M5.1.5.59'
      }
    ],
    children: [
      {
        name: 'Десна',
        runoff: { value: 23, directionTop: false },
        temperature: { value: 0.6, directionTop: true },
        precipitation: { value: 1, directionTop: false }
      },
      {
        name: 'Сейм',
        runoff: { value: 34, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 1, directionTop: false }
      },
      {
        name: 'Снов',
        runoff: { value: 13, directionTop: false },
        temperature: { value: 0.6, directionTop: true },
        precipitation: { value: 1, directionTop: true }
      },
      {
        name: 'Остер',
        runoff: { value: 37, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 2, directionTop: false }
      }
    ],
    logoBlock: {
      runoff: { value: 23, directionTop: false },
      temperature: { value: 0.6, directionTop: true },
      precipitation: { value: 1, directionTop: false }
    },
    isOpened: false
  },
  {
    name: "Суб-басейн Прип'яті",
    rivers: [
      {
        name: 'р. Прип’ять від витоку до державного кордону',
        code: 'M5.1.4.40'
      },
      {
        name: 'р. Прип’ять від г/п Мозир до гирла (в межах України)',
        code: 'M5.1.4.41'
      },
      {
        name: 'р. Стир від витоку до кордону Рівненської та Волинської областей',
        code: 'M5.1.4.42'
      },
      {
        name: 'р. Стир у межах Волинської області',
        code: 'M5.1.4.43'
      },
      {
        name: 'р. Стир від кордону Волинської та Рівненської областей до державного кордону',
        code: 'M5.1.4.44'
      },
      {
        name: 'р. Горинь від витоку до кордону Хмельницької та Рівненської областей',
        code: 'M5.1.4.45'
      },
      {
        name: 'р. Горинь від кордону Хмельницької та Рівненської областей до державного кордону (виключаючи р. Случ)',
        code: 'M5.1.4.46'
      },
      {
        name: 'р. Случ від витоку до гирла р. Хомора (включаючи р. Хомора)',
        code: 'M5.1.4.47'
      },
      {
        name: 'р. Случ від гирла р. Хомора до гирла р. Корчик (включаючи р. Корчик)',
        code: 'M5.1.4.48'
      },
      {
        name: 'р. Случ від гирла р. Корчик до гирла',
        code: 'M5.1.4.49'
      },
      {
        name: 'р. Ствига',
        code: 'M5.1.4.50'
      },
      {
        name: 'р. Уборть від витоку до державного кордону',
        code: 'M5.1.4.51'
      },
      {
        name: 'р. Уж',
        code: 'M5.1.4.52'
      }
    ],
    children: [
      {
        name: "Прип'ять",
        runoff: { value: 16, directionTop: false },
        temperature: { value: 0.6, directionTop: true },
        precipitation: { value: 1, directionTop: false }
      },
      {
        name: 'Горинь',
        runoff: { value: 31, directionTop: false },
        temperature: { value: 0.6, directionTop: true },
        precipitation: { value: 4, directionTop: false }
      },
      {
        name: 'Случ',
        runoff: { value: 33, directionTop: false },
        temperature: { value: 0.6, directionTop: true },
        precipitation: { value: 5, directionTop: false }
      },
      {
        name: 'Стир',
        runoff: { value: 19, directionTop: false },
        temperature: { value: 0.6, directionTop: true },
        precipitation: { value: 1, directionTop: false }
      },
      {
        name: 'Уж',
        runoff: { value: 29, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 4, directionTop: false }
      },
      {
        name: 'Уборть',
        runoff: { value: 26, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 3, directionTop: false }
      },
      {
        name: 'Ствига',
        runoff: { value: 18, directionTop: false },
        temperature: { value: 0.6, directionTop: true },
        precipitation: { value: 2, directionTop: false }
      },
      {
        name: 'Стохід',
        runoff: { value: 6, directionTop: false },
        temperature: { value: 0.6, directionTop: true },
        precipitation: { value: 2, directionTop: true }
      },
      {
        name: 'Турія',
        runoff: { value: 3, directionTop: true },
        temperature: { value: 0.5, directionTop: true },
        precipitation: { value: 3, directionTop: true }
      },
      {
        name: 'Моства',
        runoff: { value: 19, directionTop: false },
        temperature: { value: 0.6, directionTop: true },
        precipitation: { value: 1, directionTop: false }
      },
      {
        name: 'Іква',
        runoff: { value: 27, directionTop: false },
        temperature: { value: 0.6, directionTop: true },
        precipitation: { value: 4, directionTop: false }
      }
    ],
    logoBlock: {
      runoff: { value: 16, directionTop: false },
      temperature: { value: 0.6, directionTop: true },
      precipitation: { value: 1, directionTop: false }
    },
    isOpened: false
  },
  {
    name: 'Басейн Дністра',
    rivers: [
      {
        name: 'р. Дністер від витоку до гирла р. Стрий',
        code: 'M5.2.0.01'
      },
      {
        name: 'р. Стрий',
        code: 'M5.2.0.02'
      },
      {
        name: 'р. Дністер від гирла р. Стрий до гирла р. Гнила Липа',
        code: 'M5.2.0.03'
      },
      {
        name: 'р. Дністер від гирла р. Гнила Липа до гирла р. Серет (включаючи р. Гнила Липа та виключаючи рр. Бистриця, Серет)',
        code: 'M5.2.0.04'
      },
      {
        name: 'р. Бистриця',
        code: 'M5.2.0.05'
      },
      {
        name: 'р. Серет',
        code: 'M5.2.0.06'
      },
      {
        name: 'р. Дністер від гирла р. Серет до г/п Могилів-Подільський (виключаючи р. Збруч)',
        code: 'M5.2.0.07'
      },
      {
        name: 'р. Збруч',
        code: 'M5.2.0.08'
      },
      {
        name: 'р. Дністер від г/п Могилів-Подільський до державного кордону',
        code: 'M5.2.0.09'
      },
      {
        name: 'р. Дністер від державного кордону до гирла р. Реут (в межах України)',
        code: 'M5.2.0.10'
      },
      {
        name: 'р. Дністер від гирла р. Бик до гирла (в межах України)',
        code: 'M5.2.0.11'
      },
      {
        name: 'Дністровський лиман',
        code: 'M5.2.0.12'
      }
    ],
    children: [
      {
        name: 'Дністер',
        runoff: { value: 21, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 6, directionTop: false }
      },
      {
        name: 'Серет',
        runoff: { value: 31, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 5, directionTop: false }
      },
      {
        name: 'Збруч',
        runoff: { value: 39, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 7, directionTop: false }
      },
      {
        name: 'Стрий',
        runoff: { value: 10, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 2, directionTop: false }
      },
      {
        name: 'Бистриця',
        runoff: { value: 17, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 6, directionTop: false }
      },
      {
        name: 'Мурафа',
        runoff: { value: 54, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 10, directionTop: false }
      },
      {
        name: 'Кучурган',
        runoff: { value: 24, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 6, directionTop: false }
      }
    ],
    logoBlock: {
      runoff: { value: 21, directionTop: false },
      temperature: { value: 0.7, directionTop: true },
      precipitation: { value: 6, directionTop: false }
    },
    isOpened: false
  },
  {
    name: 'Басейн Південного Бугу',
    rivers: [
      {
        name: 'р. Південний Буг від витоку до гирла р. Іква (включаючи р. Іква)',
        code: 'M5.4.0.01'
      },
      {
        name: 'р. Південний Буг від гирла р. Іква до г/п Селище',
        code: 'M5.4.0.02'
      },
      {
        name: 'р. Південний Буг від г/п Селище до гирла р. Сільниця (включаючи р. Сільниця)',
        code: 'M5.4.0.03'
      },
      {
        name: 'р. Південний Буг від гирла р. Сільниця до гирла р. Синюха',
        code: 'M5.4.0.04'
      },
      {
        name: 'р. Тікич (включаючи рр. Гнилий Тікич, Гірський Тікич)',
        code: 'M5.4.0.05'
      },
      {
        name: 'р. Синюха (включаючи р. Велика Вись)',
        code: 'M5.4.0.06'
      },
      {
        name: 'р. Південний Буг від гирла р. Синюха до г/п Олександрівка',
        code: 'M5.4.0.07'
      },
      {
        name: 'р. Південний Буг від г/п Олександрівка до гирла (виключаючи р. Інгул)',
        code: 'M5.4.0.08'
      },
      {
        name: 'р. Інгул від витоку до гирла р. Березівка (включаючи р. Березівка)',
        code: 'M5.4.0.09'
      },
      {
        name: 'р. Інгул від гирла р. Березівка до гирла',
        code: 'M5.4.0.10'
      },
      {
        name: 'Бузький лиман',
        code: 'M5.4.0.11'
      }
    ],
    children: [
      {
        name: 'Південний Буг',
        runoff: { value: 40, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 6, directionTop: false }
      },
      {
        name: 'Синюха',
        runoff: { value: 38, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 6, directionTop: false }
      },
      {
        name: 'Інгул',
        runoff: { value: 18, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 1, directionTop: false }
      },
      {
        name: 'Гірський Тікич',
        runoff: { value: 44, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 7, directionTop: false }
      },
      {
        name: 'Гнилий Тікич',
        runoff: { value: 37, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 6, directionTop: false }
      },
      {
        name: 'Велика Вись',
        runoff: { value: 36, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 5, directionTop: false }
      },
      {
        name: 'Соб',
        runoff: { value: 55, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 8, directionTop: false }
      },
      {
        name: 'Чорний Ташлик',
        runoff: { value: 31, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 4, directionTop: false }
      },
      {
        name: 'Ятрань',
        runoff: { value: 45, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 6, directionTop: false }
      },
      {
        name: 'Чичиклія',
        runoff: { value: 21, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 3, directionTop: false }
      }
    ],
    logoBlock: {
      runoff: { value: 40, directionTop: false },
      temperature: { value: 0.7, directionTop: true },
      precipitation: { value: 6, directionTop: false }
    },
    isOpened: false
  },
  {
    name: 'Басейн Дону',
    rivers: [
      {
        name: 'р. Сіверський Донець від державного кордону до греблі Печенізького водосховища',
        code: 'M6.5.1.01'
      },
      {
        name: 'р. Сіверський Донець від греблі Печенізького водосховища до г/п Зміїв (виключаючи р. Уди)',
        code: 'M6.5.1.02'
      },
      {
        name: 'р. Уди',
        code: 'M6.5.1.03'
      },
      {
        name: 'р. Сіверський Донець від г/п Зміїв до гирла р. Берека',
        code: 'M6.5.1.04'
      },
      {
        name: 'р. Берека',
        code: 'M6.5.1.05'
      },
      {
        name: 'р. Сіверський Донець від гирла р. Берека до кордону Харківської та Донецької областей (виключаючи р. Оскіл)',
        code: 'M6.5.1.06'
      },
      {
        name: "р. Оскіл від державного кордону до г/п Куп'янськ",
        code: 'M6.5.1.07'
      },
      {
        name: "р. Оскіл від г/п Куп'янськ до гирла",
        code: 'M6.5.1.08'
      },
      {
        name: 'р. Сіверський Донець від кордону Харківської та Донецької областей до кордону Донецької та Луганської областей (виключаючи рр. Казенний Торець, Бахмутка)',
        code: 'M6.5.1.09'
      },
      {
        name: 'р. Казенний Торець',
        code: 'M6.5.1.10'
      },
      {
        name: 'р. Бахмутка',
        code: 'M6.5.1.11'
      },
      {
        name: 'р. Сіверський Донець від кордону Донецької та Луганської областей до г/п Лисичанськ (виключаючи рр. Красна, Борова)',
        code: 'M6.5.1.12'
      },
      {
        name: 'р. Красна',
        code: 'M6.5.1.13'
      },
      {
        name: 'р. Борова',
        code: 'M6.5.1.14'
      },
      {
        name: 'р. Сіверський Донець від г/п Лисичанськ до державного кордону (виключаючи рр. Айдар, Лугань, Деркул)',
        code: 'M6.5.1.15'
      },
      {
        name: 'р. Айдар',
        code: 'M6.5.1.16'
      },
      {
        name: 'р. Лугань',
        code: 'M6.5.1.17'
      },
      {
        name: 'р. Деркул',
        code: 'M6.5.1.18'
      },
      {
        name: "р. Велика Кам'янка (у межах України)",
        code: 'M6.5.1.19'
      },
      {
        name: 'притоки р. Дон',
        code: 'M6.5.2.20'
      }
    ],
    children: [
      {
        name: 'Сів.Донець',
        runoff: { value: 31, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 4, directionTop: false }
      },
      {
        name: 'Оскіл',
        runoff: { value: 32, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 3, directionTop: false }
      },
      {
        name: 'Айдар',
        runoff: { value: 31, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 4, directionTop: false }
      },
      {
        name: 'Казенний Торець',
        runoff: { value: 34, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 5, directionTop: false }
      },
      {
        name: 'Деркул',
        runoff: { value: 30, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 5, directionTop: false }
      },
      {
        name: 'Уди',
        runoff: { value: 25, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 1, directionTop: false }
      },
      {
        name: 'Лугань',
        runoff: { value: 19, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 3, directionTop: false }
      },
      {
        name: 'Красна',
        runoff: { value: 32, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 5, directionTop: false }
      },
      {
        name: 'Берека',
        runoff: { value: 51, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 4, directionTop: false }
      }
    ],
    logoBlock: {
      runoff: { value: 30, directionTop: false },
      temperature: { value: 0.8, directionTop: true },
      precipitation: { value: 4, directionTop: false }
    },
    isOpened: false
  },
  {
    name: 'Басейн Вісли',
    rivers: [
      {
        name: 'р. Західний Буг від витоку до державного кордону',
        code: 'A6.6.1.01'
      },
      {
        name: 'р. Західний Буг від державного кордону з Республікою Польща до державного кордону з Республікою Білорусь',
        code: 'A6.6.1.02'
      },
      {
        name: 'р. Сан та її притоки (в межах України)',
        code: 'A6.6.2.03'
      }
    ],
    children: [
      {
        name: 'Західний Буг',
        runoff: { value: 7, directionTop: false },
        temperature: { value: 0.6, directionTop: true },
        precipitation: { value: 1, directionTop: true }
      }
    ],
    logoBlock: {
      runoff: { value: 7, directionTop: false },
      temperature: { value: 0.6, directionTop: true },
      precipitation: { value: 1, directionTop: true }
    },
    isOpened: false
  },
  {
    name: 'Суб-басейн Тиси',
    rivers: [
      {
        name: 'р. Тиса від витоку до державного кордону',
        code: 'M5.3.1.01'
      },
      {
        name: 'р. Латориця від витоку до державного кордону',
        code: 'M5.3.1.02'
      },
      {
        name: 'р. Уж від витоку до державного кордону',
        code: 'M5.3.1.03'
      }
    ],
    children: [
      {
        name: 'Тиса',
        runoff: { value: 12, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 5, directionTop: false }
      },
      {
        name: 'Латориця',
        runoff: { value: 8, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 1, directionTop: false }
      },
      {
        name: 'Уж',
        runoff: { value: 29, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 4, directionTop: false }
      }
    ],
    logoBlock: {
      runoff: { value: 11, directionTop: false },
      temperature: { value: 0.7, directionTop: true },
      precipitation: { value: 3, directionTop: false }
    },
    isOpened: false
  },
  {
    name: 'Суб-басейн Пруту',
    rivers: [
      {
        name: 'р. Прут від витоку до державного кордону',
        code: 'M5.3.2.04'
      }
    ],
    children: [
      {
        name: 'Прут',
        runoff: { value: 19, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 8, directionTop: false }
      },
      {
        name: 'Черемош',
        runoff: { value: 16, directionTop: false },
        temperature: { value: 0.6, directionTop: true },
        precipitation: { value: 7, directionTop: false }
      }
    ],
    logoBlock: {
      runoff: { value: 19, directionTop: false },
      temperature: { value: 0.7, directionTop: true },
      precipitation: { value: 8, directionTop: false }
    },
    isOpened: false
  },
  {
    name: 'Суб-басейн Сірету',
    rivers: [
      {
        name: 'р. Сірет від витоку до державного кордону',
        code: 'M5.3.3.05'
      }
    ],
    children: [
      {
        name: 'Сірет',
        runoff: { value: 24, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 9, directionTop: false }
      }
    ],
    logoBlock: {
      runoff: { value: 24, directionTop: false },
      temperature: { value: 0.7, directionTop: true },
      precipitation: { value: 9, directionTop: false }
    },
    isOpened: false
  },
  {
    name: "Басейн річок Причорномор'я та Нижнього Дунаю",
    rivers: [
      {
        name: 'р. Дунай від державного кордону до гирла (виключаючи рр. Кагул, Ялпуг)',
        code: 'M5.3.4.06'
      },
      {
        name: 'р. Кагул (включаючи озеро Кагул)',
        code: 'M5.3.4.07'
      },
      {
        name: 'р. Ялпуг (включаючи озера Ялпуг, Кугурлуй)',
        code: 'M5.3.4.08'
      },
      {
        name: 'Узбережжя Чорного моря між гирлом р. Дунай та Дністровським лиманом',
        code: 'M5.8.0.01'
      },
      {
        name: 'Узбережжя Чорного моря між Дністровським лиманом та Дніпровським лиманом (виключаючи р. Тилігул з лиманом)',
        code: 'M5.8.0.02'
      },
      {
        name: 'р. Тилігул з лиманом',
        code: 'M5.8.0.03'
      },
      {
        name: 'Узбережжя Чорного моря між Дніпровським лиманом та Кримським півостровом',
        code: 'M5.8.0.04'
      }
    ],
    children: [
      {
        name: 'Тилігул',
        runoff: { value: 29, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 5, directionTop: false }
      }
    ],
    logoBlock: {
      runoff: { value: 8, directionTop: false },
      temperature: { value: 0.7, directionTop: true },
      precipitation: { value: 3, directionTop: false }
    },
    isOpened: false
  },
  {
    name: "Басейн річок Приазов'я",
    rivers: [
      {
        name: 'Узбережжя Азовського моря від Кримського півострова до державного кордону (виключаючи рр. Молочна, Берда, Кальміус, Міус)',
        code: 'M6.9.0.01'
      },
      {
        name: 'р. Молочна (включаючи Молочний лиман)',
        code: 'M6.9.0.02'
      },
      {
        name: 'р. Берда',
        code: 'M6.9.0.03'
      },
      {
        name: 'р. Кальміус (виключаючи р. Кальчик)',
        code: 'M6.9.0.04'
      },
      {
        name: 'р. Кальчик',
        code: 'M6.9.0.05'
      },
      {
        name: 'р. Міус від витоку до державного кордону (виключаючи р. Кринка)',
        code: 'M6.9.0.06'
      },
      {
        name: 'р. Кринка від витоку до державного кордону',
        code: 'M6.9.0.07'
      }
    ],
    children: [
      {
        name: 'Кальміус',
        runoff: { value: 23, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 4, directionTop: false }
      },
      {
        name: 'Молочна (Токмак)',
        runoff: { value: 40, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 5, directionTop: false }
      },
      {
        name: 'Кринка',
        runoff: { value: 18, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 3, directionTop: false }
      },
      {
        name: 'Міус',
        runoff: { value: 19, directionTop: false },
        temperature: { value: 0.8, directionTop: true },
        precipitation: { value: 3, directionTop: false }
      }
    ],
    logoBlock: {
      runoff: { value: 24, directionTop: false },
      temperature: { value: 0.8, directionTop: true },
      precipitation: { value: 3, directionTop: false }
    },
    isOpened: false
  },
  {
    name: 'Басейн річок Криму',
    rivers: [
      {
        name: 'Західне узбережжя Кримського півострова (виключаючи рр. Кача, Альма, Чорна, Бельбек)',
        code: 'M5.7.0.01'
      },
      {
        name: 'р. Кача',
        code: 'M5.7.0.02'
      },
      {
        name: 'р. Альма',
        code: 'M5.7.0.03'
      },
      {
        name: 'р. Чорна',
        code: 'M5.7.0.04'
      },
      {
        name: 'р. Бельбек',
        code: 'M5.7.0.05'
      },
      {
        name: 'Південне узбережжя Кримського півострова',
        code: 'M5.7.0.06'
      },
      {
        name: 'Узбережжя Азовського моря в межах Кримського півострова (виключаючи р. Салгир)',
        code: 'M6.7.0.07'
      },
      {
        name: 'р. Салгир',
        code: 'M6.7.0.08'
      }
    ],
    children: [
      {
        name: 'Салгир',
        runoff: { value: 16, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 2, directionTop: false }
      },
      {
        name: 'Чатирлик',
        runoff: { value: 20, directionTop: false },
        temperature: { value: 0.7, directionTop: true },
        precipitation: { value: 0, directionTop: true }
      }
    ],
    logoBlock: {
      runoff: { value: 16, directionTop: false },
      temperature: { value: 0.7, directionTop: true },
      precipitation: { value: 1, directionTop: false }
    },
    isOpened: false
  }
]
