export const getCookieValue = (id: string): string => {
  return (
    document.cookie
      .split('; ')
      .find((row) => {
        return row.startsWith(id)
      })
      ?.split('=')[1] || ''
  )
}

export const getApiKey = (): string => {
  return getCookieValue('APIToken')
}

export const waitForApiKey = async (interval = 200): Promise<string> => {
  const timeout = 10000
  const endTime = Date.now() + timeout

  do {
    await new Promise((resolve) => setTimeout(resolve, interval))
  } while (Date.now() < endTime && !getApiKey())

  return getApiKey()
}

export const saveToCookie = (name: string, value: string, minutes: number) => {
  const expires = new Date()
  expires.setTime(expires.getTime() + minutes * 60 * 60 * 1000)

  const encodedValue = encodeURIComponent(value)
  document.cookie = `${name}=${encodedValue};expires=${expires.toUTCString()};path=/`
}
