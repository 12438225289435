import axios, { type InternalAxiosRequestConfig } from 'axios'
import { getApiKey, waitForApiKey } from '../utils/cookieKeys'
import { refreshToken } from '../utils/refreshingKey'

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 10000
})

const onRequestSuccess = async (request: InternalAxiosRequestConfig) => {
  let key = getApiKey()
  if (!key) {
    refreshToken()
    key = await waitForApiKey()
    if (!key) {
      console.error('Waiting for API key failed')
    }
  }

  if (request.url) {
    const url = new URL(request.url, request.baseURL)
    url.searchParams.set('api_key', key)
    request.url = url.toString().replace(url.origin, '')
  } else {
    console.error('Unable to retrieve API key: \n', key, '\n', request.url)
  }

  return request
}

axiosInstance.interceptors.request.use(onRequestSuccess, (error) => {
  return Promise.reject(error)
})

export default axiosInstance
