<template>
  <div class="dropdown" ref="dropdown">
    <button @click="openSeasonDropdown">
      <img src="/image/dataPage/dataBlock/calendar.svg" alt="calendar" />
    </button>
    <div v-show="showSeasons" class="dropdown-menu base-list">
      <div class="title-period body1"></div>
      <div
        class="select-button"
        :class="{
          active: selectedSeason === season
        }"
        @click="selectSeason(season)"
        v-for="season in seasons"
        :key="season.name"
      >
        <div class="inner">{{ $t('dataPage.period.period') }} {{ season }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, defineEmits, watch } from 'vue'

const props = defineProps({
  modelValue: String
})

const emit = defineEmits(['update:modelValue'])

const seasons = ['2021-2040', '2041-2060', '2061-2080', '2081-2100']

const dropdown = ref(null)
const selectedSeasonObject = ref(null)
const selectedSeason = ref('2021-2040')
const showSeasons = ref(false)

watch(selectedSeason, (newValue) => {
  emit('update:modelValue', newValue)
})

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue !== selectedSeason.value) {
      selectedSeason.value = newValue
    }
  }
)

function openSeasonDropdown() {
  showSeasons.value = true
}

function selectSeason(season) {
  if (season.name) {
    selectedSeason.value = season.name
  } else {
    selectedSeason.value = season
  }

  if (season.child) {
    selectedSeasonObject.value = season.child
  } else {
    if (
      selectedSeason.value !== 'spring' ||
      selectedSeason.value !== 'summer' ||
      selectedSeason.value !== 'autumn' ||
      selectedSeason.value !== 'winter'
    ) {
      selectedSeasonObject.value = null
      showSeasons.value = false
    }
  }
}

const closeDropdown = (event) => {
  if (!dropdown.value.contains(event.target)) {
    showSeasons.value = false
    selectedSeasonObject.value = null
  }
}

onMounted(() => {
  document.addEventListener('click', closeDropdown)
})

onUnmounted(() => {
  document.removeEventListener('click', closeDropdown)
})
</script>

<style scoped>
.title-period {
  color: var(--color-secondary-blue);
  padding: 35px 24px 10px;
}

.dropdown {
  position: relative;
  display: inline-block;

  button {
    width: 48px;
    height: 48px;
    position: relative;
    border: none;
    margin-right: 12px;
    text-align: center;
    border-radius: 8px;
    background: var(--color-light-gray);
    cursor: pointer;
  }
}

.base-list {
  position: absolute;
  top: 55px;
  background-color: var(--color-white);
  min-width: 160px;
  box-shadow: 0px 1px 3px 0px #0000004d;
  z-index: 1;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  .select-button {
    padding: 12px 16px;
    cursor: pointer;
    white-space: nowrap;

    &.active {
      color: var(--color-dark-gray);
      cursor: not-allowed;
    }
  }

  .inner {
    height: 24px;
    width: 184px;
    padding: 8px;
  }

  .select-button:hover .inner {
    background-color: var(--color-light-gray);
  }
}
</style>
