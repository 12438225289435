import { ref, type Ref } from 'vue'
import { defineStore } from 'pinia'
import { dataSubbasins } from '@/UI/Header/components/BasinDropDown/ChildData'

export const useGlobalStore = defineStore('global', () => {
  const is10Models: Ref<string> = ref(false)
  const setIs10Models = (newIs10Models: boolean) => {
    is10Models.value = newIs10Models
  }

  const selectedBasin: Ref<string> = ref('Вся Україна')
  const setSelectedBasin = (newSelectedBasin: string) => {
    selectedBasin.value = newSelectedBasin
  }

  const selectedBasinCode: Ref<string> = ref('1')
  const setSelectedBasinCode = (newSelectedBasinCode: string) => {
    selectedBasinCode.value = newSelectedBasinCode
  }

  const selectedBigBasinCode: Ref<string> = ref('1')
  const setSelectedBigBasinCode = (newSelectedBigBasinCode: string) => {
    selectedBigBasinCode.value = newSelectedBigBasinCode
  }

  const selectedBasinChildren = ref(dataSubbasins[0].children)
  const setSelectedBasinChildren = (newSelectedBasinChildren) => {
    selectedBasinChildren.value = newSelectedBasinChildren
  }

  const selectedBasinRivers = ref(dataSubbasins[0].rivers)
  const setSelectedBasinRivers = (newSelectedBasinRivers) => {
    selectedBasinRivers.value = newSelectedBasinRivers
  }

  const logoBlockInfo = ref(dataSubbasins[0].logoBlock)
  const setLogoBlockInfo = (newLogoBlockInfo) => {
    logoBlockInfo.value = newLogoBlockInfo
  }

  const units: Ref<'relative' | 'absolute'> = ref('relative')
  const setUnits = (newUnits: 'relative' | 'absolute') => {
    units.value = newUnits
  }

  const sceneriesAtmosphere: Ref<'rcp85' | 'rcp45'> = ref('rcp85')
  const setSceneriesAtmosphere = (newSceneriesAtmosphere: 'rcp85' | 'rcp45') => {
    sceneriesAtmosphere.value = newSceneriesAtmosphere
  }

  const projectResearch: Ref<'CMIP6' | 'CORDEX-EUR-11'> = ref('CORDEX-EUR-11')
  const setProjectResearch = (newProjectResearch: 'CMIP6' | 'CORDEX-EUR-11') => {
    projectResearch.value = newProjectResearch
  }

  const territory: Ref<'UkraineOnly' | 'Transboundary'> = ref('Transboundary')
  const setTerritory = (newTerritory: 'UkraineOnly' | 'Transboundary') => {
    territory.value = newTerritory
  }

  const rainAndTemperature: Ref<'ERA5-Land' | 'observation' | 'ObservationsWind'> = ref('ERA5-Land')
  const setRainAndTemperature = (
    newRainAndTemperature: 'ERA5-Land' | 'observation' | 'ObservationsWind'
  ) => {
    rainAndTemperature.value = newRainAndTemperature
  }

  const evaporation: Ref<'fact' | 'potential'> = ref('potential')
  const setEvaporation = (newEvaporation: 'fact' | 'potential') => {
    evaporation.value = newEvaporation
  }

  const textSize: Ref<number> = ref(1)
  const textSizeStep = 0.1

  const textSizePlus = () => {
    textSize.value += textSizeStep
  }

  const textSizeMinus = () => {
    textSize.value -= textSizeStep
  }

  const resetMenuSetting = () => {
    setUnits('relative')
    setSceneriesAtmosphere('rcp85')
    setProjectResearch('CORDEX-EUR-11')
    setTerritory('Transboundary')
    setRainAndTemperature('ERA5-Land')
    setEvaporation('potential')
  }

  const firstLoad = ref(true)

  return {
    firstLoad,
    is10Models,
    setIs10Models,
    selectedBasin,
    setSelectedBasin,
    selectedBasinRivers,
    setSelectedBasinRivers,
    selectedBasinCode,
    setSelectedBasinCode,
    selectedBigBasinCode,
    setSelectedBigBasinCode,
    selectedBasinChildren,
    setSelectedBasinChildren,
    logoBlockInfo,
    setLogoBlockInfo,
    units,
    setUnits,
    sceneriesAtmosphere,
    setSceneriesAtmosphere,
    projectResearch,
    setProjectResearch,
    territory,
    setTerritory,
    rainAndTemperature,
    setRainAndTemperature,
    evaporation,
    setEvaporation,
    textSize,
    textSizePlus,
    textSizeMinus,
    resetMenuSetting
  }
})
