<script lang="ts" setup>
import CloudflareWidget from './cloudflare/CloudflareWidget.vue'
import { onMounted, ref } from 'vue'
import { getApiKey, saveToCookie, waitForApiKey } from './utils/cookieKeys'
import { checkGoogleIpRequest } from './utils/checkGoogleIpRequest'
import { refreshToken } from './utils/refreshingKey'
import { useAuthStore } from './store/authStore'

const isGoogle = ref(false)
const isMasterKeyExist = ref(false)
const authStore = useAuthStore()

onMounted(async () => {
  console.log('Check master key')
  if (import.meta.env.VITE_MASTER_TOKEN) {
    isMasterKeyExist.value = true
    saveToCookie('APIToken', import.meta.env.VITE_MASTER_TOKEN, 9999)
    return
  }

  console.log('Check google ip')
  isGoogle.value = await checkGoogleIpRequest()
  if (isGoogle.value) {
    return
  }

  if (!getApiKey()) {
    authStore.isFirstLoad = true
    console.log('Getting lib key')
    refreshToken()
  } else {
    console.log('Api key already exists')
  }

  const resultWaiting = await waitForApiKey()
  if (resultWaiting) {
    authStore.setApikeyLoaded()
  } else {
    console.error('Failed to get lib key by opening page')
  }

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      console.log('User back to this web page')

      if (!getApiKey()) {
        console.log('Getting lib key')
        refreshToken()
      } else {
        console.log('Api key already exists')
      }
    } else {
      console.log('User leave this web page')
    }
  })
})
</script>

<template>
  <!-- TODO: add notifications component -->
  <CloudflareWidget v-if="!isGoogle && !isMasterKeyExist" />
</template>
