import axios from 'axios'
import { useAuthStore } from '../store/authStore'
import { saveToCookie } from '../utils/cookieKeys'
import { watch } from 'vue'
import type { Store } from 'pinia'

let authStore: Store | null = null

const initPiniaStore = () => {
  if (!authStore) {
    authStore = useAuthStore()

    watch(
      () => (authStore as Store<'authStore', { turnstileToken: string }>).turnstileToken,
      async (token) => {
        if (token) {
          ;(
            authStore as Store<'authStore', { startServerGettingKey: () => {} }>
          ).startServerGettingKey()
          const key = await getApiKeyFromServer(token)
          if (key) {
            saveToCookie('APIToken', key, 9)
          }
          ;(
            authStore as Store<'authStore', { clearTurnstileToken: () => {} }>
          ).clearTurnstileToken()
          ;(
            authStore as Store<'authStore', { endServerGettingKey: () => {} }>
          ).endServerGettingKey()
        }
      }
    )
  }
}

export const getApiKeyFromServer = async (token: string) => {
  const baseUrl = import.meta.env.VITE_API_URL
  const envQuery = process.env.NODE_ENV === 'development' ? '&domain=localhost' : ''
  const url = `${baseUrl}/api_key/?response_token=${token}${envQuery}`
  let apiKey: string

  try {
    const resp = await axios.get(url)
    apiKey = resp.data.api_key
  } catch (e) {
    if (e?.response.data?.detail === 'Access Denied') {
      console.error('Server problem with getting API key')
    } else if (e?.response.status.toString().startsWith('5')) {
      return ''
    }
    console.error('Internal Server Error')
    return ''
  }

  return apiKey || ''
}

export const refreshToken = () => {
  initPiniaStore()

  if (authStore.isAuthProcessing) {
    return
  }
  authStore.startCloudflare()
}
