<template>
  <div class="tabs">
    <div class="tab discharge" :class="{ active: props.tab === 'discharge' }">
      <div class="inner">
        <router-link to="/data/discharge/">
          <div class="icon">
            <img src="/image/dataPage/tabs/discharge-default.png" alt="discharge" />
          </div>
          <div class="label1-bold">{{ $t('dataPage.tabs.discharge') }}</div>
        </router-link>
      </div>
      <div class="stripe"></div>
    </div>
    <div class="stroke"></div>
    <div class="tab runoff" :class="{ active: props.tab === 'water_flow' }">
      <div class="inner">
        <router-link to="/data/water_flow/">
          <div class="icon">
            <img src="/image/dataPage/tabs/runoff-default.svg" alt="runoff" />
          </div>
          <div class="label1-bold">{{ $t('dataPage.tabs.water_flow') }}</div>
        </router-link>
      </div>
      <div class="stripe"></div>
    </div>
    <div class="stroke"></div>
    <div class="tab temperature" :class="{ active: props.tab === 'temperature' }">
      <div class="inner">
        <router-link to="/data/temperature/">
          <div class="icon">
            <img src="/image/dataPage/tabs/temperature-default.svg" alt="temperature" />
          </div>
          <div class="label1-bold">{{ $t('dataPage.tabs.temperature') }}</div>
        </router-link>
      </div>
      <div class="stripe"></div>
    </div>
    <div class="stroke"></div>
    <div class="tab precipitation" :class="{ active: props.tab === 'precipitation' }">
      <div class="inner">
        <router-link to="/data/precipitation/">
          <div class="icon">
            <img src="/image/dataPage/tabs/precipitation-default.svg" alt="precipitation" />
          </div>
          <div class="label1-bold">{{ $t('dataPage.tabs.precipitation') }}</div>
        </router-link>
      </div>
      <div class="stripe"></div>
    </div>
    <div class="stroke"></div>
    <div class="tab vaporization" :class="{ active: props.tab === 'evapotranspiration' }">
      <div class="inner">
        <router-link to="/data/evapotranspiration/">
          <div class="icon">
            <img src="/image/dataPage/tabs/vaporization-default.svg" alt="vaporization" />
          </div>
          <div class="label1-bold">{{ $t('dataPage.tabs.evapotranspiration') }}</div>
        </router-link>
      </div>
      <div class="stripe"></div>
    </div>
    <div class="stroke"></div>
    <div class="tab groundsoil" :class="{ active: props.tab === 'groundwater' }">
      <div class="inner">
        <router-link to="/data/groundwater/">
          <div class="icon">
            <img src="/image/dataPage/tabs/soilwater-default.svg" alt="soilwater" />
          </div>
          <div class="label1-bold">{{ $t('dataPage.tabs.groundwater') }}</div>
        </router-link>
      </div>
      <div class="stripe"></div>
    </div>
    <div class="stroke"></div>
    <div class="tab soilwater" :class="{ active: props.tab === 'soil_water' }">
      <div class="inner">
        <router-link to="/data/soil_water/">
          <div class="icon">
            <img src="/image/dataPage/tabs/groundsoil-default.svg" alt="groundsoil" />
          </div>
          <div class="label1-bold">{{ $t('dataPage.tabs.soil_water') }}</div>
        </router-link>
      </div>
      <div class="stripe"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'

interface Props {
  tab:
    | 'discharge'
    | 'water_flow'
    | 'temperature'
    | 'precipitation'
    | 'evapotranspiration'
    | 'groundwater'
    | 'soil_water'
}

const props = defineProps<Props>()
</script>

<style scoped lang="scss">
.tabs {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid var(--color-middle-gray);
}

.tab {
  position: relative;
  display: flex;
  justify-content: start;
  //width: 196px;
  width: 100%;
  height: 48px;

  .stripe {
    display: none;
    position: absolute;
    height: 2px;
    width: 48px;
    bottom: 0;
    left: calc(50% - 24px);
    border-radius: 5px 5px 0 0;
  }

  &.active {
    .stripe {
      display: block;
    }
  }

  .inner {
    width: 172px;
    margin: 8px 12px;
    border-radius: 4px;
    cursor: pointer;

    a {
      display: flex;
      justify-content: start;
    }

    &:hover .icon .hovered {
      display: block;
    }

    &:hover .icon .default {
      display: none;
    }
  }

  &:last-child {
    .inner {
      margin-right: 0;
      width: 92%;
    }
  }

  .icon {
    height: 32px;
    width: 32px;
    border-radius: 4px;
    margin-right: 8px;

    img {
      margin: auto;
      margin-top: 4px;
    }
  }

  .icon img {
    display: block;
  }

  .icon .hovered {
    display: none;
  }

  .label1-bold {
    font-weight: 700;
    margin-top: 4px;
  }

  &.discharge {
    color: var(--color-stroke-water-loss);

    .icon {
      background-color: var(--color-fill-water-loss);
    }

    .inner:hover {
      background-color: var(--color-fill-water-loss);
    }

    .stripe {
      background-color: var(--color-stroke-water-loss);
    }
  }

  &.runoff {
    color: var(--color-stroke-volume-of-water);

    .icon {
      background-color: var(--color-fill-volume-of-water);
    }

    .inner:hover {
      background-color: var(--color-fill-volume-of-water);
    }

    .stripe {
      background-color: var(--color-stroke-volume-of-water);
    }
  }

  &.temperature {
    color: var(--color-stroke-temperature);

    .icon {
      background-color: var(--color-fill-temperature);
    }

    .inner:hover {
      background-color: var(--color-fill-temperature);
    }

    .stripe {
      background-color: var(--color-stroke-temperature);
    }
  }

  &.precipitation {
    color: var(--color-stroke-presepitation);

    .icon {
      background-color: var(--color-fill-presepitation);
    }

    .inner:hover {
      background-color: var(--color-fill-presepitation);
    }

    .stripe {
      background-color: var(--color-stroke-presepitation);
    }
  }

  &.vaporization {
    color: var(--color-stroke-vaporization);

    .icon {
      background-color: var(--color-fill-vaporization);
    }

    .inner:hover {
      background-color: var(--color-fill-vaporization);
    }

    .stripe {
      background-color: var(--color-stroke-vaporization);
    }
  }

  &.groundsoil {
    color: var(--color-stroke-soil-water);

    .icon {
      background-color: var(--color-fill-soil-water);
    }

    .inner:hover {
      background-color: var(--color-fill-soil-water);
    }

    .stripe {
      background-color: var(--color-stroke-soil-water);
    }
  }

  &.soilwater {
    color: var(--color-stroke-humidy);

    .icon {
      background-color: var(--color-fill-humidy);
    }

    .inner:hover {
      background-color: var(--color-fill-humidy);
    }

    .stripe {
      background-color: var(--color-stroke-humidy);
    }
  }
}

.stroke {
  height: 16px;
  width: 1px;
  background-color: var(--color-middle-gray);
  margin: auto 0;
}
</style>
