export enum NotificationEnum {
  Success = 'success',
  Error = 'error',
  Warning = 'warning'
}

export enum PositionEnum {
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_LEFT = 'bottom-left'
}

export type Notification = {
  id: string
  type: NotificationEnum
  message: string
}

export type NotificationInput = Omit<Notification, 'id'>
