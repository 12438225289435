import { defineStore } from 'pinia'

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    isCloudflareStart: false,
    isServerGettingKeyStart: false,
    turnstileToken: '',
    apikeyLoaded: false,
    isFirstLoad: false
  }),
  getters: {
    isAuthProcessing: (state) => state.isCloudflareStart || state.isServerGettingKeyStart
  },
  actions: {
    startCloudflare() {
      this.isCloudflareStart = true
    },
    endCloudflare() {
      this.isCloudflareStart = false
    },
    startServerGettingKey() {
      this.isCloudflareStart = true
    },
    endServerGettingKey() {
      this.isCloudflareStart = false
    },
    setTurnstileToken(token: string) {
      this.turnstileToken = token
    },
    clearTurnstileToken() {
      this.turnstileToken = ''
    },
    setApikeyLoaded() {
      this.apikeyLoaded = true
    }
  }
})
