<template>
  <div class="outer">
    <table class="data-table">
      <thead>
        <tr>
          <th class="label1">
            <span v-html="$t('mainPage.table.riverBasin')"></span>
          </th>
          <th class="label1" style="padding-left: 26px">
            <span v-html="$t('mainPage.table.theVolumeRunoff')"></span>, %*
          </th>
          <th class="label1">
            <span v-html="$t('mainPage.table.temperature')"></span>
            °C*
          </th>
          <th class="label1"><span v-html="$t('mainPage.table.fall')"></span>, %*</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="row in globalStore.selectedBasinChildren" :key="row.name">
          <td class="label1">{{ row.name }}</td>
          <td class="body1" style="padding-left: 26px">
            <img
              :class="{
                low: !row.runoff.directionTop
              }"
              src="./image/arrow.svg"
              alt="arrow"
            />
            {{ row.runoff.value }}
          </td>
          <td class="body1 temp">
            <img
              :class="{
                low: !row.temperature.directionTop
              }"
              src="./image/arrow.svg"
              alt="arrow"
            />
            {{ row.temperature.value }}
          </td>
          <td class="body1">
            <img
              :class="{
                low: !row.precipitation.directionTop
              }"
              src="./image/arrow.svg"
              alt="arrow"
            />
            {{ row.precipitation.value }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useGlobalStore } from '@/stores/globalStore'

const globalStore = useGlobalStore()

const data = computed(globalStore.selectedBasinChildren)

//   reactive([
//   {
//     basin: 'Десна',
//     flowVolume: {
//       low: true,
//       value: '28,3'
//     },
//     temperature: {
//       low: true,
//       value: '8'
//     },
//     precipitation: {
//       low: true,
//       value: '12'
//     }
//   },
//   {
//     basin: 'Дніпро',
//     flowVolume: {
//       low: true,
//       value: '28,3'
//     },
//     temperature: {
//       low: true,
//       value: '8'
//     },
//     precipitation: {
//       low: true,
//       value: '12'
//     }
//   },
//   {
//     basin: 'Тиса',
//     flowVolume: {
//       low: true,
//       value: '21,8'
//     },
//     temperature: {
//       low: true,
//       value: '6'
//     },
//     precipitation: {
//       low: true,
//       value: '8'
//     }
//   },
//   {
//     basin: 'Базавлук',
//     flowVolume: {
//       low: true,
//       value: '11,3'
//     },
//     temperature: {
//       low: true,
//       value: '4'
//     },
//     precipitation: {
//       low: true,
//       value: '17'
//     }
//   },
//   {
//     basin: 'Дунай',
//     flowVolume: {
//       low: true,
//       value: '28,3'
//     },
//     temperature: {
//       low: true,
//       value: '1'
//     },
//     precipitation: {
//       low: true,
//       value: '12'
//     }
//   },
//   {
//     basin: 'Псел',
//     flowVolume: {
//       low: true,
//       value: '17,1'
//     },
//     temperature: {
//       low: true,
//       value: '8'
//     },
//     precipitation: {
//       low: true,
//       value: '4'
//     }
//   },
//   {
//     basin: 'Сів.Донець',
//     flowVolume: {
//       low: true,
//       value: '21,8'
//     },
//     temperature: {
//       low: true,
//       value: '6'
//     },
//     precipitation: {
//       low: true,
//       value: '8'
//     }
//   },
//   {
//     basin: 'Супій',
//     flowVolume: {
//       low: true,
//       value: '11,3'
//     },
//     temperature: {
//       low: true,
//       value: '4'
//     },
//     precipitation: {
//       low: true,
//       value: '17'
//     }
//   },
//   {
//     basin: 'Снигур',
//     flowVolume: {
//       low: true,
//       value: '28,3'
//     },
//     temperature: {
//       low: true,
//       value: '8'
//     },
//     precipitation: {
//       low: true,
//       value: '12'
//     }
//   },
//   {
//     basin: 'Рось',
//     flowVolume: {
//       low: true,
//       value: '28,3'
//     },
//     temperature: {
//       low: true,
//       value: '8'
//     },
//     precipitation: {
//       low: true,
//       value: '12'
//     }
//   }
// ])
</script>

<style scoped lang="scss">
.outer {
  position: relative;
  width: calc(33% - 5px);
  margin-left: 18px;
}

.data-table {
  position: absolute;
  box-shadow: 0 4px 8px 3px #00000026;
  border-collapse: unset;
  border-radius: 8px;
  height: fit-content;

  th,
  td {
    border: none;
    padding: 15px;
    text-align: left;
  }

  td.temp {
    img {
      filter: hue-rotate(180deg);
    }
  }

  td img {
    width: 15px;
    height: 15px;

    //display: none;
    //margin-right: 15px;
    margin: 0 3px;

    &.low {
      filter: hue-rotate(180deg);
      transform: scaleY(-1);
    }
  }

  thead {
    background-image: var(--color-blue-linear1);
    color: white;
    width: 100%;
    padding: 16px;

    th:first-child {
      width: 100px;
    }
  }

  tbody {
    td {
      vertical-align: middle;
    }

    tr {
      height: 56px;
    }

    tr:nth-child(odd) {
      background-color: var(--color-white);
    }

    tr:nth-child(even) {
      background-color: var(--color-light-gray);
    }

    .body1 {
      //text-align: center;
    }
  }

  th:first-child {
    border-top-left-radius: 10px;
  }

  th:last-child {
    border-top-right-radius: 10px;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
}
</style>
