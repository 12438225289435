<script lang="ts" setup>
import VueTurnstile from 'vue-turnstile'
// import { NotificationEnum, useNotificationsStore } from "@/widgets/notifications";
import { onMounted, ref, watch } from 'vue'
import { getApiKey } from '../utils/cookieKeys'
import { useAuthStore } from '../store/authStore'

const turnstileToken = ref('')

const authStore = useAuthStore()
const turnstileRef = ref(null)

const siteKey = import.meta.env.VITE_SITE_KEY
const showTurnstileWidget = ref(false)

watch(turnstileToken, (value) => {
  if (value) {
    authStore.setTurnstileToken(value)
    authStore.endCloudflare()
  }
})

watch(
  () => authStore.isCloudflareStart,
  (isCloudflareStart) => {
    if (isCloudflareStart) {
      showTurnstileWidget.value = true
      turnstileRef.value?.reset() // It works
    }
  }
)

const onUnsupported = () => {
  console.log('The Cloudflare widget is not supported in this browser version')
}

const onError = (error: string) => {
  console.log(error)
}

watch(
  () => authStore.turnstileToken,
  (token) => {
    if (token) {
      if (!authStore.isFirstLoad) {
        setTimeout(() => {
          showTurnstileWidget.value = false
        }, 2000)
        return
      }
      setTimeout(() => {
        showTurnstileWidget.value = false
        authStore.isFirstLoad = false
      }, 500)
    }
  }
)

onMounted(() => {
  if (!getApiKey()) {
    showTurnstileWidget.value = true
  }
})
</script>

<template>
  <div
    :class="{
      center: authStore.isFirstLoad
    }"
    v-if="showTurnstileWidget"
    class="outer"
  >
    <VueTurnstile
      ref="turnstileRef"
      v-model="turnstileToken"
      :class="{
        center: authStore.isFirstLoad
      }"
      :reset-interval="550000"
      :site-key="siteKey"
      class="widget"
      language="en"
      render-on-mount
      size="normal"
      theme="dark"
      @error="onError"
      @unsupported="onUnsupported"
    />
  </div>
</template>

<style lang="scss" scoped>
.center.outer {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(60, 60, 60, 0.5);
  height: 100vh;
  width: 100vw;
  z-index: 99;
}

.center.widget {
  position: absolute;
  bottom: calc(50% - 35px) !important;
  left: calc(50% - 150px) !important;
}

.widget {
  position: absolute;
  bottom: 46px;
  left: 5px;
}
</style>
