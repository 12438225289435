import { defineStore } from 'pinia'
import type { Notification, NotificationInput } from '@/widgets/notifications/types.ts'

export const useNotificationsStore = defineStore('notifications', {
  state: (): { notifications: Notification[]; notificationsToShow: Notification[] } => ({
    notifications: [],
    notificationsToShow: []
  }),
  getters: {
    isNotificationVisible: (state) => {
      return (id: string) => {
        return state.notificationsToShow.some((notification) => notification.id === id)
      }
    }
  },
  actions: {
    addNotification(notification: NotificationInput) {
      const id = Date.now().toString()
      this.notifications.push({ ...notification, id })
      this.notificationsToShow.push({ ...notification, id })

      setTimeout(() => {
        this.removeNotification(id)
      }, 5000) // disable notifications
    },
    removeNotification(id: string) {
      this.notificationsToShow = this.notificationsToShow.filter((notification: Notification) => {
        return notification.id !== id
      })

      setTimeout(() => {
        this.notifications = this.notifications.filter((notification: Notification) => {
          return notification.id !== id
        })
      }, 500)
    }
  }
})
