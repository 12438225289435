<template>
  <teleport to="#hint">
    <div class="hint" ref="screenshotHintArea">
      <div class="hint-inner body1">
        <div class="title-small label1-bold" v-html="hintText"></div>
        <div class="title-small label1-bold" v-html="medinaText"></div>
        <div class="mods">
          <template v-if="props.selectedMod === 'median'">
            <div class="mod">Мод.1 — {{ mod1 }} {{ getUnits() }};</div>
            <div class="mod">Мод.2 — {{ mod2 }} {{ getUnits() }};</div>
            <div class="mod">Мод.3 — {{ mod3 }} {{ getUnits() }};</div>
            <div class="mod">Мод.4 — {{ mod4 }} {{ getUnits() }};</div>
            <div class="mod">Мод.5 — {{ mod5 }} {{ getUnits() }};</div>
            <div class="mod">Мод.6 — {{ mod6 }} {{ getUnits() }};</div>
          </template>
          <template v-if="props.selectedMod === 'Mod1_EC-EARTH_HIRHAM5'">
            <div class="mod">Мод.1 — {{ mod1 }} {{ getUnits() }};</div>
          </template>
          <template v-if="props.selectedMod === 'Mod2_EC-EARTH_RACMO22E'">
            <div class="mod">Мод.2 — {{ mod2 }} {{ getUnits() }};</div>
          </template>
          <template v-if="props.selectedMod === 'Mod3_IPSL-CM5A-MR_RCA4'">
            <div class="mod">Мод.3 — {{ mod3 }} {{ getUnits() }};</div>
          </template>
          <template v-if="props.selectedMod === 'Mod4_HadGEM-ES_RACMO22E'">
            <div class="mod">Мод.4 — {{ mod4 }} {{ getUnits() }};</div>
          </template>
          <template v-if="props.selectedMod === 'Mod5_HadGEM2-ES_RCA4'">
            <div class="mod">Мод.5 — {{ mod5 }} {{ getUnits() }};</div>
          </template>
          <template v-if="props.selectedMod === 'Mod6_MPI-ESM-LR_RCA4'">
            <div class="mod">Мод.6 — {{ mod6 }} {{ getUnits() }};</div>
          </template>
        </div>

        <div>Норма за 1991-2020 рр. — <span class="body1-bold">260м³/с</span></div>
        <div>Період {{ props.selectedPeriodYear }} рр.;</div>
        <div>Реф. 1991-2020 рр.;</div>
        <div>
          Сценарій викидів — <span class="body1-bold" v-if="props.scenario === 'rcp85'">RCP8.5</span
          ><span class="body1-bold" v-if="props.scenario === 'rcp45'">RCP4.5</span>
        </div>
        <!--        {{props.description}}-->
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import axiosInstance from '@/lib/axios/index.js'
import { useGlobalStore } from '@/stores/globalStore'
import { useI18n } from 'vue-i18n'

interface DescriptionType {
  name_ua: string
  code: string
}

interface HintComponentProps {
  description: DescriptionType
  selectedTab: string
  selectedModel: string
  selectedPeriodYear: string
  periodSeason: string
  scenario: string
  selectedMod: string
}

const props = defineProps<HintComponentProps>()

const globalStore = useGlobalStore()

const getData = async (
  code: string,
  source: string,
  scenario: string,
  season: string,
  year_range: string,
  value_type: string
) => {
  if (!['annual', 'spring', 'summer', 'autumn', 'winter'].includes(season.toLowerCase())) {
    season = season.charAt(0).toUpperCase() + season.slice(1)
  }

  try {
    const response = await axiosInstance.get('/climate_water/', {
      params: {
        code: code,
        source: source,
        scenario: scenario,
        season: season,
        year_range: year_range,
        value_type: value_type,
        area: 'whole'
      }
    })
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}

const getParameterToDisplay = computed(() => {
  if (props.selectedTab === 'evapotranspiration') {
    if (globalStore.evaporation === 'potential') return 'potential_evapotranspiration'
    if (globalStore.evaporation === 'fact') return 'evapotranspiration'
  }

  return props.selectedTab
})

const getDataFromResponses = (el) => {
  if (getParameterToDisplay.value === 'water_flow') {
    return el['water_flow_outlet'] ? el['water_flow_outlet'] : el['water_flow_area']
  }
  return el[getParameterToDisplay.value]
}

const { locale } = useI18n()
const currentLocale = computed(() => locale.value)

const units = computed(() => {
  let pidpis = null

  if (props.selectedTab === 'temperature') {
    pidpis = '℃'
  }

  if (currentLocale.value === 'uk') {
    if (props.selectedTab === 'discharge') {
      pidpis = 'м³/с'
    }

    if (props.selectedTab === 'water_flow') {
      pidpis = 'млн. м³'
    }

    if (
      props.selectedTab !== 'discharge' &&
      props.selectedTab !== 'water_flow' &&
      props.selectedTab !== 'temperature'
    ) {
      pidpis = 'мм'
    }
  }

  if (currentLocale.value === 'en') {
    if (props.selectedTab === 'discharge') {
      pidpis = 'm³/s'
    }

    if (props.selectedTab === 'water_flow') {
      pidpis = 'mln. m³'
    }

    if (
      props.selectedTab !== 'discharge' &&
      props.selectedTab !== 'water_flow' &&
      props.selectedTab !== 'temperature'
    ) {
      pidpis = 'mm'
    }
  }

  if (globalStore.evaporation === 'potential' && props.selectedTab === 'evapotranspiration') {
    pidpis = '%'
  }

  if (globalStore.units === 'relative' && props.selectedTab !== 'temperature') {
    pidpis = '%'
  }

  return pidpis
})
const getUnits = () => {
  let pidpis = null

  if (props.selectedTab === 'temperature') {
    pidpis = '℃'
  }

  if (currentLocale.value === 'uk') {
    if (props.selectedTab === 'discharge') {
      pidpis = 'м³/с'
    }

    if (props.selectedTab === 'water_flow') {
      pidpis = 'млн. м³'
    }

    if (
      props.selectedTab !== 'discharge' &&
      props.selectedTab !== 'water_flow' &&
      props.selectedTab !== 'temperature'
    ) {
      pidpis = 'мм'
    }
  }

  if (currentLocale.value === 'en') {
    if (props.selectedTab === 'discharge') {
      pidpis = 'm³/s'
    }

    if (props.selectedTab === 'water_flow') {
      pidpis = 'mln. m³'
    }

    if (
      props.selectedTab !== 'discharge' &&
      props.selectedTab !== 'water_flow' &&
      props.selectedTab !== 'temperature'
    ) {
      pidpis = 'mm'
    }
  }

  if (globalStore.evaporation === 'potential' && props.selectedTab === 'evapotranspiration') {
    pidpis = '%'
  }

  if (globalStore.units === 'relative' && props.selectedTab !== 'temperature') {
    pidpis = '%'
  }

  return pidpis
}

const hintText = computed(() => props.description.name_ua)
const medina = ref('')
const mod1 = ref('')
const mod2 = ref('')
const mod3 = ref('')
const mod4 = ref('')
const mod5 = ref('')
const mod6 = ref('')
const medinaText = computed(() => `Медіана — ${props.description.nomer} ${getUnits()}`)

async function fetchAllData(code, scenarios, season, year, units) {
  const models = [
    'Mod1_EC-EARTH_HIRHAM5',
    'Mod2_EC-EARTH_RACMO22E',
    'Mod3_IPSL-CM5A-MR_RCA4',
    'Mod4_HadGEM-ES_RACMO22E',
    'Mod5_HadGEM2-ES_RCA4',
    'Mod6_MPI-ESM-LR_RCA4'
  ]

  const requests = models.map((model) => getData(code, model, scenarios, season, year, units))

  const responses = await Promise.all(requests)
  return responses.map((response) => getDataFromResponses(response))
}

async function updateModels() {
  const [mod1local, mod2local, mod3local, mod4local, mod5local, mod6local] = await fetchAllData(
    props.description.code,
    props.scenario,
    props.periodSeason,
    props.selectedPeriodYear,
    globalStore.units
  )

  mod1.value = mod1local
  mod2.value = mod2local
  mod3.value = mod3local
  mod4.value = mod4local
  mod5.value = mod5local
  mod6.value = mod6local
}

watch(
  [
    () => props.description,
    () => globalStore.sceneriesAtmosphere,
    () => props.periodSeason,
    () => props.selectedPeriodYear,
    () => globalStore.units
  ],
  async () => {
    await updateModels()
  },
  {
    immediate: true
  }
)
</script>

<style scoped lang="scss">
.mods {
  color: var(--color-primary-blue);
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: flex-start;
}

.mod {
  display: flex;
  justify-content: start;
  align-items: center;
  width: calc(50% - 10px);
  margin-right: 10px;
}

.title-small {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #082f57;
}

.hint {
  z-index: 99 !important;
  position: relative;
  height: 38px;
  width: 312px;

  &-inner {
    position: relative;
    background: rgba(255, 255, 255, 1);
    border-radius: 5px;
    padding: 11px 17px;
    justify-content: space-between;
    z-index: 3;

    div {
      margin: 4px 0;
    }
  }
}
</style>
