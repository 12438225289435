import './assets/main.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { plugin as vueTransitionsPlugin } from '@morev/vue-transitions'
import '@morev/vue-transitions/styles'

import App from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue'
import i18n from './i18n/i18n'

const app = createApp(App)

app.use(
  vueTransitionsPlugin({
    // Default duration for all transitions now is `200`
    defaultProps: {
      duration: 400
    }
  })
)

app.use(createPinia())

// Sentry.init({
//   app,
//   dsn: 'https://cc7bca68f88326ecb9d18c982ca5a253@o4506835005341696.ingest.us.sentry.io/4508103296811008',
//   integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for tracing.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
//
//   // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
//   tracePropagationTargets: [/^https:\/\/yourserver\.io\/api/],
//
//   // Capture Replay for 10% of all sessions,
//   // plus for 100% of sessions with an error
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0
// })

app.use(router)

app.use(i18n)
app.mount('#app')
