import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DataPageView from '../views/DataPageView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import PageNotFinished from '@/views/PageNotFinished.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/data/:tab',
      name: 'dataPage',
      component: DataPageView,
      beforeEnter: (to, from, next) => {
        const validTabs = [
          'discharge',
          'water_flow',
          'temperature',
          'precipitation',
          'evapotranspiration',
          'groundwater',
          'soil_water'
        ]
        if (validTabs.includes(to.params.tab)) {
          next()
        } else {
          next({ name: 'NotFound' })
        }
      }
    },
    {
      path: '/glossary',
      name: 'glossary',
      component: PageNotFinished
    },
    {
      path: '/how-to-use-platform',
      name: 'how',
      component: PageNotFinished
    },
    {
      path: '/data-source',
      name: 'data',
      component: PageNotFinished
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: NotFoundView
    }
  ]
})

export default router
