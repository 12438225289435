import axios from 'axios'

export const checkGoogleIpRequest = async () => {
  let response
  try {
    response = await axios.get(import.meta.env.VITE_API_URL + '/verify_asn/')
  } catch (error) {
    if (error?.response.status === 403) {
      return false
    }

    if (error?.response.status === 200) {
      return true
    }
  }

  console.error('Unhandled response from server', response)
  return false
}
