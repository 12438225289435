export const subbasinsWithCodes = {
  'Вся Україна': '1',
  'Суб-басейн Десни': '5.1.5',
  'Суб-басейн Середнього Дніпра': '5.1.2',
  'Суб-басейн Нижнього Дніпра': '5.1.3',
  "Суб-басейн Прип'яті": '5.1.4',
  'Басейн Дністра': 'M5.2',
  'Басейн Південного Бугу': 'M5.4',
  'Басейн Дону': 'M6.5',
  'Басейн Вісли': 'A6.6',
  'Суб-басейн Тиси': '5.3.1',
  'Суб-басейн Пруту': '5.3.2',
  'Суб-басейн Сірету': '5.3.3',
  "Басейн річок Причорномор'я та Нижнього Дунаю": 'M5.8_5.3.4',
  "Басейн річок Приазов'я": 'M6.9',
  'Басейн річок Криму': 'M5.7',
  'Суб-басейн Верхнього Дніпра': '5.1.1',
  'Бузький лиман': 'M5.4.0.11',
  'Дніпровський лиман': 'M5.1.3.39',
  'Дністровський лиман': 'M5.2.0.12',
  'Західне узбережжя Кримського півострова (виключаючи рр. Кача, Альма, Чорна, Бельбек)':
    'M5.7.0.01',
  'Київське водосховище (включаючи р. Брагінка у межах України, виключаючи рр. Прип’ять, Тетерів, Ірпінь)':
    'M5.1.2.02',
  'Південне узбережжя Кримського півострова': 'M5.7.0.06',
  'притоки р. Дон': 'M6.5.2.20',
  'р. Альма': 'M5.7.0.03',
  'р. Бельбек': 'M5.7.0.05',
  'р. Берда': 'M6.9.0.03',
  'р. Бистриця': 'M5.2.0.05',
  'р. Вовча (виключаючи рр. Мокрі Яли, Гайчур)': 'M5.1.3.32',
  'р. Ворскла від державного кордону до кордону Сумської та Полтавської областей': 'M5.1.2.22',
  'р. Ворскла від кордону Сумської та Полтавської областей до гирла': 'M5.1.2.23',
  'р. Гайчур': 'M5.1.3.34',
  'р. Горинь від витоку до кордону Хмельницької та Рівненської областей': 'M5.1.4.45',
  'р. Горинь від кордону Хмельницької та Рівненської областей до державного кордону (виключаючи р. Случ)':
    'M5.1.4.46',
  'р. Десна від г/п Чернігів до гирла (виключаючи р. Остер)': 'M5.1.5.55',
  'р. Десна від гирла р. Сейм до г/п Чернігів (виключаючи рр. Сейм, Снов)': 'M5.1.5.54',
  'р. Десна від державного кордону до гирла р. Сейм': 'M5.1.5.53',
  'р. Дніпро від греблі Дніпровського водосховища до греблі Каховського водосховища': 'M5.1.3.26',
  'р. Дніпро від греблі Дніпродзержинського водосховища до греблі Дніпровського водосховища (виключаючи рр. Оріль, Самара)':
    'M5.1.3.25',
  'р. Дніпро від греблі Канівського водосховища до греблі Кременчуцького водосховища (виключаючи рр. Рось, Супій, Сула, Тясмин)':
    'M5.1.2.04',
  'р. Дніпро від греблі Каховського водосховища до гирла (виключаючи р. Інгулець)': 'M5.1.3.27',
  'р. Дніпро від греблі Київського водосховища до греблі Канівського водосховища (виключаючи рр. Десна, Трубіж)':
    'M5.1.2.03',
  'р. Дніпро від греблі Кременчуцького водосховища до греблі Дніпродзержинського водосховища':
    'M5.1.2.24',
  'р. Дніпро від державного кордону до початку Київського водосховища (включаючи р. Сож у межах України)':
    'M5.1.1.01',
  'р. Дністер від витоку до гирла р. Стрий': 'M5.2.0.01',
  'р. Дністер від г/п Могилів-Подільський до державного кордону': 'M5.2.0.09',
  'р. Дністер від гирла р. Гнила Липа до гирла р. Серет (включаючи р. Гнила Липа та виключаючи рр. Бистриця, Серет)':
    'M5.2.0.04',
  'р. Дністер від гирла р. Стрий до гирла р. Гнила Липа': 'M5.2.0.03',
  'р. Дністер від гирла р. Бик до гирла (в межах України)': 'M5.2.0.11',
  'р. Дністер від гирла р. Серет до г/п Могилів-Подільський (виключаючи р. Збруч)': 'M5.2.0.07',
  'р. Дністер від державного кордону до гирла р. Реут (в межах України)': 'M5.2.0.10',
  'р. Дунай від державного кордону до гирла (виключаючи рр. Кагул, Ялпуг)': 'M5.3.4.06',
  'р. Західний Буг від витоку до державного кордону': 'A6.6.1.01',
  'р. Західний Буг від державного кордону з Республікою Польща до державного кордону з Республікою Білорусь':
    'A6.6.1.02',
  'р. Збруч': 'M5.2.0.08',
  'р. Інгул від витоку до гирла р. Березівка (включаючи р. Березівка)': 'M5.4.0.09',
  'р. Інгул від гирла р. Березівка до гирла': 'M5.4.0.10',
  'р. Інгулець від витоку до кордону Кіровоградської та Дніпропетровської областей': 'M5.1.3.35',
  'р. Інгулець від кордону Дніпропетровської та Херсонської областей до гирла': 'M5.1.3.37',
  'р. Інгулець від кордону Кіровоградської та Дніпропетровської областей до кордону Дніпропетровської та Херсонської областей (виключаюч':
    'M5.1.3.36',
  'р. Ірпінь': 'M5.1.2.08',
  'р. Кагул (включаючи озеро Кагул)': 'M5.3.4.07',
  'р. Кальміус (виключаючи р. Кальчик)': 'M6.9.0.04',
  'р. Кальчик': 'M6.9.0.05',
  'р. Кача': 'M5.7.0.02',
  'р. Кринка від витоку до державного кордону': 'M6.9.0.07',
  'р. Латориця від витоку до державного кордону': 'M5.3.1.02',
  'р. Міус від витоку до державного кордону (виключаючи р. Кринка)': 'M6.9.0.06',
  'р. Мокрі Яли': 'M5.1.3.33',
  'р. Молочна (включаючи Молочний лиман)': 'M6.9.0.02',
  'р. Оріль від витоку до кордону Харківської та Дніпропетровської областей': 'M5.1.3.28',
  'р. Оріль від кордону Харківської та Дніпропетровської областей до гирла': 'M5.1.3.29',
  'р. Остер': 'M5.1.5.59',
  'р. Південний Буг від витоку до гирла р. Іква (включаючи р. Іква)': 'M5.4.0.01',
  'р. Південний Буг від г/п Олександрівка до гирла (виключаючи р. Інгул)': 'M5.4.0.08',
  'р. Південний Буг від г/п Селище до гирла р. Сільниця (включаючи р. Сільниця)': 'M5.4.0.03',
  'р. Південний Буг від гирла р. Іква до г/п Селище': 'M5.4.0.02',
  'р. Південний Буг від гирла р. Синюха до г/п Олександрівка': 'M5.4.0.07',
  'р. Південний Буг від гирла р. Сільниця до гирла р. Синюха': 'M5.4.0.04',
  'р. Прип’ять від витоку до державного кордону': 'M5.1.4.40',
  'р. Прип’ять від г/п Мозир до гирла (в межах України)': 'M5.1.4.41',
  'р. Прут від витоку до державного кордону': 'M5.3.2.04',
  'р. Псел від гирла р. Хорол до гирла (виключаючи р. Хорол)': 'M5.1.2.20',
  'р. Псел від державного кордону до кордону Сумської та Полтавської областей': 'M5.1.2.18',
  'р. Псел від кордону Сумської та Полтавської областей до гирла р. Хорол': 'M5.1.2.19',
  'р. Рось від витоку до кордону Київської та Черкаської областей': 'M5.1.2.10',
  'р. Рось від кордону Київської та Черкаської областей до гирла': 'M5.1.2.11',
  'р. Саксагань': 'M5.1.3.38',
  'р. Самара від витоку до гирла р. Вовча': 'M5.1.3.30',
  'р. Самара від гирла р. Вовча до гирла (виключаючи р. Вовча)': 'M5.1.3.31',
  'р. Сан та її притоки (в межах України)': 'A6.6.2.03',
  'р. Сейм від г/п Мутин до гирла': 'M5.1.5.57',
  'р. Сейм від державного кордону до г/п Мутин': 'M5.1.5.56',
  'р. Серет': 'M5.2.0.06',
  'р. Синюха (включаючи р. Велика Вись)': 'M5.4.0.06',
  'р. Сіверський Донець від г/п Зміїв до гирла р. Берека': 'M6.5.1.04',
  'р. Сіверський Донець від г/п Лисичанськ до державного кордону (виключаючи рр. Айдар, Лугань, Деркул)':
    'M6.5.1.15',
  'р. Сіверський Донець від греблі Печенізького водосховища до г/п Зміїв (виключаючи р. Уди)':
    'M6.5.1.02',
  'р. Сірет від витоку до державного кордону': 'M5.3.3.05',
  'р. Случ від витоку до гирла р. Хомора (включаючи р. Хомора)': 'M5.1.4.47',
  'р. Случ від гирла р. Корчик до гирла': 'M5.1.4.49',
  'р. Случ від гирла р. Хомора до гирла р. Корчик (включаючи р. Корчик)': 'M5.1.4.48',
  'р. Снов': 'M5.1.5.58',
  'р. Ствига': 'M5.1.4.50',
  'р. Стир від витоку до кордону Рівненської та Волинської областей': 'M5.1.4.42',
  'р. Стир від кордону Волинської та Рівненської областей до державного кордону': 'M5.1.4.44',
  'р. Стир у межах Волинської області': 'M5.1.4.43',
  'р. Стрий': 'M5.2.0.02',
  'р. Сула від витоку до кордону Сумської та Полтавської областей': 'M5.1.2.13',
  'р. Сула від г/п Лубни до гирла': 'M5.1.2.15',
  'р. Сула від кордону Сумської та Полтавської областей до г/п Лубни (виключаючи р. Удай)':
    'M5.1.2.14',
  'р. Супій': 'M5.1.2.12',
  'р. Тетерів від витоку до г/п Житомир': 'M5.1.2.05',
  'р. Тетерів від г/п Житомир до гирла р. Ірша (включаючи р. Ірша)': 'M5.1.2.06',
  'р. Тетерів від гирла р. Ірша до гирла': 'M5.1.2.07',
  'р. Тиса від витоку до державного кордону': 'M5.3.1.01',
  'р. Тікич (включаючи рр. Гнилий Тікич, Гірський Тікич)': 'M5.4.0.05',
  'р. Трубіж': 'M5.1.2.09',
  'р. Тясмин': 'M5.1.2.17',
  'р. Уборть від витоку до державного кордону': 'M5.1.4.51',
  'р. Удай': 'M5.1.2.16',
  'р. Уди': 'M6.5.1.03',
  'р. Уж': 'M5.1.4.52',
  'р. Уж від витоку до державного кордону': 'M5.3.1.03',
  'р. Хорол': 'M5.1.2.21',
  'р. Чорна': 'M5.7.0.04',
  'р. Ялпуг (включаючи озера Ялпуг, Кугурлуй)': 'M5.3.4.08',
  'р. Айдар': 'M6.5.1.16',
  'р. Бахмутка': 'M6.5.1.11',
  'р. Берека': 'M6.5.1.05',
  'р. Борова': 'M6.5.1.14',
  "р. Велика Кам'янка (у межах України)": 'M6.5.1.19',
  'р. Деркул': 'M6.5.1.18',
  'р. Казенний Торець': 'M6.5.1.10',
  'р. Красна': 'M6.5.1.13',
  'р. Лугань': 'M6.5.1.17',
  "р. Оскіл від г/п Куп'янськ до гирла": 'M6.5.1.08',
  "р. Оскіл від державного кордону до г/п Куп'янськ": 'M6.5.1.07',
  'р. Салгир': 'M6.7.0.08',
  'р. Сіверський Донець від гирла р. Берека до кордону Харсківської та Донецької областей (виключаючи р. Оскіл)':
    'M6.5.1.06',
  'р. Сіверський Донець від державного кордону до греблі Печенізького водосховища': 'M6.5.1.01',
  'р. Сіверський Донець від кордону Донецької та Луганської областей до г/п Лисичанськ (виключаючи рр. Красна, Борова)':
    'M6.5.1.12',
  'р. Сіверський Донець від кордону Харківської та Донецької областей до кордону Донецької та Луганської областей (виключаючи рр. Казенний Торець, Бахмутка)':
    'M6.5.1.09',
  'р. Тилігул з лиманом': 'M5.8.0.03',
  'Узбережжя Азовського моря в межах Кримського півострова (виключаючи р. Салгир)': 'M6.7.0.07',
  'Узбережжя Азовського моря від Кримського півострова до державного кордону (виключаючи рр. Молочна, Берда, Кальміус, Міус)':
    'M6.9.0.01',
  'Узбережжя Чорного моря між гирлом р. Дунай та Дністровським лиманом': 'M5.8.0.01',
  'Узбережжя Чорного моря між Дніпровським лиманом та Кримським півостровом': 'M5.8.0.04',
  'Узбережжя Чорного моря між Дністровським лиманом та Дніпровським лиманом (виключаючи р. Тилігул з лиманом)':
    'M5.8.0.02'
}
