<script setup lang="ts">
import { computed, defineProps } from 'vue'
import { useGlobalStore } from '@/stores/globalStore'
import legends from './legends.json'
import { useI18n } from 'vue-i18n'

interface Props {
  tab:
    | 'discharge'
    | 'water_flow'
    | 'temperature'
    | 'precipitation'
    | 'evapotranspiration'
    | 'groundwater'
    | 'soil_water'
}

const props = defineProps<Props>()
const globalStore = useGlobalStore()

const selectedLegend = computed(() => legends[props.tab][globalStore.units])

const { locale } = useI18n()
const currentLocale = computed(() => locale.value)

const getUnits = () => {
  let pidpis = null

  if (props.tab === 'temperature') {
    pidpis = '℃'
  }

  if (currentLocale.value === 'uk') {
    if (props.tab === 'discharge') {
      pidpis = 'м³/с'
    }

    if (props.tab === 'water_flow') {
      pidpis = 'млн. м³'
    }

    if (props.tab !== 'discharge' && props.tab !== 'water_flow' && props.tab !== 'temperature') {
      pidpis = 'мм'
    }
  }

  if (currentLocale.value === 'en') {
    if (props.tab === 'discharge') {
      pidpis = 'm³/s'
    }

    if (props.tab === 'water_flow') {
      pidpis = 'mln. m³'
    }

    if (props.tab !== 'discharge' && props.tab !== 'water_flow' && props.tab !== 'temperature') {
      pidpis = 'mm'
    }
  }

  if (globalStore.evaporation === 'potential' && props.tab === 'evapotranspiration') {
    pidpis = '%'
  }

  if (globalStore.units === 'relative' && props.tab !== 'temperature') {
    pidpis = '%'
  }

  return pidpis
}
</script>

<template>
  <div class="legend">
    <div class="legend-item" v-for="(legend, index) in selectedLegend" :key="legend.value">
      <div
        class="square"
        :style="{
          backgroundColor: legend.color
        }"
      ></div>
      <div class="line"></div>
      <div class="value label1-bold">
        {{ legend.value }} <template v-if="index === 0">{{ getUnits() }}</template>
      </div>
    </div>
    <div class="units label1-bold"></div>
  </div>
</template>

<style scoped lang="scss">
.legend {
  position: relative;
  margin-top: 24px;
  margin-left: 32px;

  .units {
    position: absolute;
    top: 0;
    right: 3px;
    color: var(--color-dark-gray);
  }

  .legend-item {
    display: flex;

    &:first-child {
      .line {
        border-top: 2px solid var(--color-dark-gray);
      }
    }
  }

  .square {
    width: 24px;
    height: 40px;
  }

  .value {
    color: var(--color-dark-gray);
    margin-left: 8px;
  }

  .line {
    border-right: 2px solid var(--color-dark-gray);
    border-bottom: 2px solid var(--color-dark-gray);
    width: 10px;
    margin-left: 16px;
  }
}
</style>
