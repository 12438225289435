<template>
  <div class="map-block">
    <div class="scenario body1-bold" v-if="mapSyncStore.isCompareMap">
      <template v-if="props.scenario === 'rcp85'">RCP8.5</template>
      <template v-if="props.scenario === 'rcp45'">RCP4.5</template>
    </div>
    <div ref="mapContainer" id="map" class="map-container"></div>
  </div>
  <div class="buttons-block"></div>
<!--{{globalStore.selectedBigBasinCode}}-->
<!--{{globalStore.selectedBasinCode}}-->
  <hintComponent
    v-if="showHint && hintPolygonId && styleLoaded"
    :description="description"
    :period-season="props.periodSeason"
    :selected-model="props.selectedMod"
    :selected-period-year="props.periodYears"
    :selected-tab="props.selectedTab"
    :scenario="props.scenario"
    :selectedMod="selectedMod"
  />
  <template v-if="false">
    {{ description }}

    showHint {{ showHint }}<br />
    hintPolygonId {{ hintPolygonId }}<br />
    styleLoaded {{ styleLoaded }}
  </template>
</template>

<script lang="ts" setup>
import { watch, nextTick, onMounted, onUnmounted, type Ref, ref, computed } from 'vue'
import * as maptilersdk from '@maptiler/sdk'
import basins from './geojsons/basins.json'
import smallBasins from './geojsons/smallBasins_compresed.json'
import dots from './geojsons/dots.json'
import hintRemover from './geojsons/hintRemover.json'
import legends from './legend/legends.json'
import rivers from './geojsons/river.json'
import centers from './geojsons/centers.json'
import hintComponent from '@/components/dataPage/components/MapComponent/HintComponent.vue'
import('@maptiler/sdk/dist/maptiler-sdk.css')
import { useMapSyncStore } from '@/stores/mapSyncStore'
import axiosInstance from '@/lib/axios'
import { useGlobalStore } from '@/stores/globalStore'

const mapContainer = ref(null)
const description = ref({})

const showHint = ref(false)
const hintPolygonId = ref(null)
const styleLoaded = ref(false)
const mapRef = ref(null)

const globalStore = useGlobalStore()

interface Props {
  periodSeason: string
  selectedTab: string
  periodYears: string
  scenario: string
  selectedMod: string
}

const props = defineProps<Props>()

type Coordinates = [number, number]

interface Cache {
  [key: string]: Coordinates | null
}

const cache: Cache = centers

const findCenter = (id: string, coordinates: Coordinates[]): Coordinates | null => {
  if (cache[id]) {
    return cache[id]
  }

  if (coordinates.length === 0) {
    cache[id] = null
    return null
  }

  let x = 0
  let y = 0

  for (let i = 0; i < coordinates.length; i++) {
    const [coordX, coordY] = coordinates[i]
    x += coordX
    y += coordY
  }

  const centerX = x / coordinates.length
  const centerY = y / coordinates.length
  const result: Coordinates = [centerX, centerY]

  cache[id] = result
  return result
}

const mapSyncStore = useMapSyncStore()

const closeHint = (event) => {
  if (!event.target.closest(`#map`)) {
    showHint.value = false
  }
}

onMounted(async () => {
  const map = new maptilersdk.Map({
    container: mapContainer.value,
    style: `https://api.maptiler.com/maps/87bc86b0-be50-42e3-8c05-f78258a31d48/style.json?key=BvrtwMrSBaJInDrAfqu9`,
    center: [31.1656, 48.3794],
    zoom: 4.2
  })

  mapRef.value = map

  mapSyncStore.addMapForSync(map)

  map.on('load', () => {
    map.addSource('basinsSource', {
      type: 'geojson',
      data: basins
    })

    map.addSource('smallBasinsSource', {
      type: 'geojson',
      data: smallBasins
    })

    map.addSource('riversSource', {
      type: 'geojson',
      data: rivers
    })

    map.addLayer({
      id: 'riversLayer',
      type: 'line',
      source: 'riversSource',
      layout: {},
      paint: {
        'line-color': 'rgba(77,151,228,0.5)', // Цвет линии обводки
        'line-width': 1 // Толщина линии
      }
    })

    map.addSource('dotsSource', {
      type: 'geojson',
      data: dots
    })

    map.addLayer({
      id: 'smallBasinsLayer',
      type: 'fill',
      source: 'smallBasinsSource',
      layout: {},
      paint: {
        'fill-opacity': 0.75,
        'fill-outline-color': 'rgba(255,255,255,0.9)'
      }
    })

    const res = []
    fillValuesWithColors(legends[props.selectedTab][globalStore.units]).forEach((el) =>
        res.unshift(el.value, el.color)
    )
    console.log(res)

    map.setPaintProperty('smallBasinsLayer', 'fill-color', [
      "case",
      ["==", ["get", "nomer"], null], "#ffffff",
      ["step",
        // red is higher when feature.properties.temperature is higher
        ["coalesce", ["get", "nomer"], 0],
        '#7a0718',         // Значение по умолчанию
        ...res,
      ]])

    map.addLayer({
      id: 'basinsLayer',
      type: 'line',
      source: 'basinsSource',
      layout: {},
      paint: {
        'line-color': '#8a9db2',
        'line-width': 3
      }
    })

    map.addLayer({
      id: 'dotsLayer',
      type: 'circle',
      source: 'dotsSource',
      layout: {},
      paint: {
        'circle-radius': 5,
        'circle-color': '#6C7681'
      }
    })

    if (props.selectedTab === 'discharge' || props.selectedTab === 'water_flow') {
      map.setLayoutProperty('dotsLayer', 'visibility', 'visible')
      map.setLayoutProperty('smallBasinsLayer', 'visibility', 'none')
    } else {
      map.setLayoutProperty('dotsLayer', 'visibility', 'none')
      map.setLayoutProperty('smallBasinsLayer', 'visibility', 'visible')
    }

    map.addSource('hintRemoverSource', {
      type: 'geojson',
      data: hintRemover
    })

    map.addLayer({
      id: 'removerHintLayer',
      type: 'fill',
      source: 'hintRemoverSource',
      layout: {},
      paint: {
        'fill-color': 'rgba(7, 78, 184, 0)',
        'fill-outline-color': 'rgba(184,7,81, 0)'
      }
    })

    map.addLayer({
      id: 'big-polygon-outline',
      type: 'line',
      source: 'basinsSource',
      paint: {
        'line-color': 'rgba(17, 59, 102, 1)',
        'line-width': 3
      },
      filter: ['==', ['get', 'code'], ''] // Изначально ничего не выбирается
    })

    map.addLayer({
      id: 'small-polygon-outline',
      type: 'line',
      source: 'smallBasinsSource',
      paint: {
        'line-color': 'rgba(17, 59, 102, 1)',
        'line-width': 1.5
      },
      filter: ['==', ['get', 'code'], ''] // Изначально ничего не выбирается
    })

    styleLoaded.value = true

    let popup = new maptilersdk.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: [-235, 10]
    })

    map.on('mousemove', 'smallBasinsLayer', (e) => {
      map.getCanvas().style.cursor = 'pointer'
      if (
        e.features === undefined ||
        e.features.length === 0 ||
        e.features[0].properties.nomer === null ||
        e.features[0].properties.nomer === undefined
      ) {
        return
      }
      let coordinates =
        e.features[0].geometry.coordinates[0][0].length > 2
          ? e.features[0].geometry.coordinates[0][0]
          : e.features[0].geometry.coordinates[0]
      description.value = e.features[0].properties
      const polygonId = e.features[0].properties.id

      showHint.value = false
      nextTick(() => (showHint.value = true))

      popup
        .setLngLat(findCenter(e.features[0].properties.id, coordinates))
        .setHTML(`<div id='hint'></div>`)
        .addTo(map)

      nextTick(() => {
        hintPolygonId.value = polygonId
      })

      if (e.features.length > 0) {
        if (hintPolygonId.value !== null) {
          map.setFeatureState(
            { source: 'smallBasinsSource', id: hintPolygonId.value },
            { hover: false }
          )
        }

        hintPolygonId.value = +e.features[0].id
      }
    })

    map.on('mousemove', 'removerHintLayer', () => {
      map.getCanvas().style.cursor = ''

      nextTick(() => {
        if (hintPolygonId.value !== null) {
          map.setFeatureState(
            { source: 'smallBasinsSource', id: hintPolygonId.value },
            { hover: false }
          )
        }
        hintPolygonId.value = null
      })

      popup.remove()
    })
  })

  document.addEventListener('mouseover', closeHint)
})

onUnmounted(() => {
  mapSyncStore.removeMapForSync()
  document.removeEventListener('mouseover', closeHint)
})

const getParameterToDisplay = computed(() => {
  if (props.selectedTab === 'evapotranspiration') {
    if (globalStore.evaporation === 'potential') return 'potential_evapotranspiration'
    if (globalStore.evaporation === 'fact') return 'evapotranspiration'
  }

  return props.selectedTab
})

const getDataFromResponses = (el, code) => {
  if(el === null){
    // console.log('data not found for code ', code, `${}`)
    return null
  }
  let res = null
  if (getParameterToDisplay.value === 'water_flow') {
    if(el['water_flow_outlet'] === null){
      res = el['water_flow_area']
    } else {
      res = el['water_flow_outlet']
    }
  } else {
    res = el[getParameterToDisplay.value]
  }
  if(res === null){
    console.log('res is null: ', el, '\nwith code: ', code)
    return null
  }
  // return parseInt(res.toFixed(0))
  return res
}

const getData = async (
  code: CodeType,
  source: SourceType,
  scenario: ScenarioType,
  season: SeasonType,
  year_range: YearRangeType,
  value_type: ValueType
) => {
  if (
    season !== 'annual' &&
    season !== 'spring' &&
    season !== 'summer' &&
    season !== 'autumn' &&
    season !== 'winter'
  ) {
    season = season.charAt(0).toUpperCase() + season.slice(1)
  }

  return (
    await axiosInstance.get('/climate_water/', {
      params: {
        code: code,
        source: source,
        scenario: scenario,
        season: season,
        year_range: year_range,
        value_type: value_type,
        area: 'whole'
      }

      // Example request
      // https://test.api.uhmi.org.ua/climate_water/
      // code=5.1.1
      // source=Mod4_HadGEM-ES_RACMO22E
      // scenario=rcp45
      // year_range=2021-2040
      // season=August
      // value_type=relative
      // area=whole
    })
  ).data
}

function fillValuesWithColors(data) {
  return data
  let result = []

  for (let i = 0; i < data.length; i++) {
    result.push(data[i])

    if (i < data.length - 1) {
      const current = data[i]
      const next = data[i + 1]

      for (let value = current.value - 1; value > next.value; value -= 1) {
        result.push({ color: next.color, value: parseFloat(value.toFixed(1)) })
      }
    }
  }

  return result
}

watch(
  [
    () => globalStore.selectedBigBasinCode,
    () => globalStore.selectedBasinCode,
    () => globalStore.units,
    () => props.selectedMod,
    () => props.selectedTab,
    () => props.scenario,
    () => props.periodYears,
    () => props.periodSeason,
    () => styleLoaded.value
  ],
  async () => {
    if(!styleLoaded.value){
      console.log('Styles not loaded yet')
      return
    }
    if(globalStore.selectedBigBasinCode === "1"){
      mapRef.value?.setFilter('big-polygon-outline', ['all']);
      mapRef.value?.setFilter('small-polygon-outline', []);
    } else {
      mapRef.value?.setFilter('big-polygon-outline', [
        '==',
        ['get', 'code'],
        globalStore.selectedBigBasinCode
      ]);
    }

      if(globalStore.selectedBigBasinCode !== globalStore.selectedBasinCode){
        mapRef.value?.setFilter('small-polygon-outline', [
          '==',
          ['get', 'code'],
          globalStore.selectedBasinCode
        ]);
      } else {
        mapRef.value?.setFilter('small-polygon-outline', [
          '==',
          ['get', 'code'],
          999999
        ]);
      }

    const smallBasinsNew = await import('./geojsons/smallBasins_compresed.json')
    const requests = []

    if (props.selectedTab !== 'discharge' && props.selectedTab !== 'water_flow') {
      for (const feature of smallBasinsNew.features) {
        let nomerId = null
        if (feature.properties['subbasin_code'] === globalStore.selectedBigBasinCode) {
          nomerId = feature.properties['subbasin_code']
        } else if (feature.properties['basin_code'] === globalStore.selectedBigBasinCode) {
          nomerId = feature.properties['basin_code']
        } else if (globalStore.selectedBigBasinCode === '1'){
          if(feature.properties['subbasin_code']){
            nomerId = feature.properties['subbasin_code']
          } else if (feature.properties['basin_code']){
            nomerId = feature.properties['basin_code']
          }
        }

        if (nomerId) {
          const request = getData(
            feature.properties['code'],
            props.selectedMod,
            props.scenario,
            props.periodSeason,
            props.periodYears,
            globalStore.units
          ).then((data) => {
            feature.properties['nomer'] = getDataFromResponses(data, feature.properties['code'])
            return feature // Optional: return modified feature if needed elsewhere
          })
          requests.push(request)
        } else {
          feature.properties['nomer'] = null
        }
      }
    }

    await Promise.all(requests)

    mapRef.value?.getSource('smallBasinsSource').setData(smallBasinsNew)

    const res = []
    fillValuesWithColors(legends[props.selectedTab][globalStore.units]).forEach((el) =>
        res.unshift(el.value, el.color)
    )
    console.log(res)


    // mapRef.value?.setPaintProperty('smallBasinsLayer', 'fill-color', [
    //   'step',
    //   ['get', 'nomer'],
    //   ...res,
    //   'white'
    // ])
    mapRef.value?.setPaintProperty('smallBasinsLayer', 'fill-color', [
      "case",
      ["==", ["get", "nomer"], null], "#ffffff",
      ["step",
        // red is higher when feature.properties.temperature is higher
        ["coalesce", ["get", "nomer"], 0],
        '#7a0718',         // Значение по умолчанию
        ...res,
        // 1, '#FED976',     // Если value >= 10, используем цвет #FED976
        // 2, '#FEB24C',     // Если value >= 20, используем цвет #FEB24C
        // 3, '#FD8D3C',     // Если value >= 30, используем цвет #FD8D3C
        // 4, '#FC4E2A',     // Если value >= 40, используем цвет #FC4E2A
        // 5, '#E31A1C',      // Если value >= 50, используем цвет #E31A1C]
      ]])
  },
  { immediate: true }
)

watch(
  () => props.selectedTab,
  (newTab, oldTab) => {
    if (newTab === 'discharge' || newTab === 'water_flow') {
      if (oldTab !== 'discharge' && oldTab !== 'water_flow') {
        mapRef.value?.setLayoutProperty('dotsLayer', 'visibility', 'visible')
        mapRef.value?.setLayoutProperty('smallBasinsLayer', 'visibility', 'none')
      }
      return
    }

    if (newTab !== 'discharge' && newTab !== 'water_flow') {
      if (oldTab === 'discharge' || oldTab === 'water_flow') {
        mapRef.value?.setLayoutProperty('dotsLayer', 'visibility', 'none')
        mapRef.value?.setLayoutProperty('smallBasinsLayer', 'visibility', 'visible')
      }
    }
  },
  {
    immediate: true
  }
)
</script>

<style scoped>
.map-block {
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
  position: relative;

  .scenario{
    position: absolute;
    z-index: 1;
    color: var(--color-primary-blue);
    left: 12px;
    top: 12px;
  }
}

.map-container {
  height: 404px;
  width: 100%;
  border: 1px solid #6c7681;
  border-radius: 7px;
}
</style>
