import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    utils: {
      ukraine: 'Ukraine',
      pageNotFound: 'Page not found'
    },
    header: {
      menu: {
        is10Models: {
          name: 'Data from climate models',
          options: {
            notIs10ModelsLabel: 'Group by minimum, mean and maximum',
            is10ModelsLabel: 'Show data for 10 models'
          }
        },
        amount: {
          name: 'Amount',
          options: {
            variable: 'Relative',
            absolute: 'Absolute value, units. (not for all parameters)'
          }
        },
        scenario: {
          name: 'Scenario of emissions into the atmosphere',
          options: {
            SSP5: 'SSP5-8.5 (RCP8.5) is a high emissions scenario',
            SSP2: 'SSP2-4.5 (RCP4.5) is a moderate emissions scenario'
          }
        },
        project: {
          name: 'Research project',
          options: {
            CMIP6: 'CMIP6',
            CORDEX: 'CORDEX-EUR-11'
          }
        },
        area: {
          name: 'Coverage area',
          options: {
            ukraine: 'Within the territory of Ukraine',
            transboundary: 'Taking into account the transboundary part of the basins'
          }
        },
        precipitation: {
          name: 'Precipitation and temperature',
          options: {
            reanalysis: 'Climate reanalysis (ERA5-Land)',
            observation: 'Observation',
            observationWind: 'Observations corrected for wind'
          }
        },
        evaporation: {
          name: 'Evaporation',
          options: {
            actual: 'Actual',
            potentially: 'Potentially'
          }
        },
        glossary: {
          name: 'Glossary'
        },
        howTo: {
          name: 'How to use the platform'
        },
        reset: {
          name: 'Reset to default settings'
        },
        access: {
          name: 'Accessibility tools',
          options: {
            textSize: 'Text size',
            cancel: 'Cancel'
          }
        }
      },
      selectBasin: 'Choose a basin'
    },
    mainPage: {
      logoBlock: {
        describe: 'Data portal on the impact of climate change on water resources in Ukraine',
        goToData: 'Go to the data'
      },
      infoBlock: {
        growTemperature: 'Increase in temperature over the past 15 years*',
        fallTemperature: 'Decrease in temperature over the past 15 years*',
        growPrecipitation: 'Increase in precipitation over the past 15 years*',
        fallPrecipitation: 'Decrease in precipitation over the past 15 years*',
        growVolume: 'Increase in runoff volume over the past 15 years*',
        fallVolume: 'Decrease in runoff volume over the past 15 years*'
      },
      description:
        '*Average value over the past 15 years (2009-2023) compared to the period 1991-2020.',
      table: {
        riverBasin: 'River',
        theVolumeRunoff: 'Runoff',
        temperature: 'Temp,',
        fall: 'Precipitation'
      },
      aboutPlatform: {
        title:
          '<span style="font-weight: bold;">Climate & Water (C & W)</span> is a user-friendly web service that visualizes the results of modeling the impact of climate change on water resources.',
        text1:
          'C & W contains data on air temperature, precipitation, water runoff, evaporation, groundwater, and soil moisture. The algorithms use the most precise hydrological model of Ukraine, historical climate data, and an ensemble of the latest high-resolution climate models.',
        text2:
          'For user convenience, the results are aggregated by river basins and water household areas in Ukraine. Users can explore annual, seasonal, or monthly values in chart or map format. All data is freely available and can be downloaded.',
        attention: 'The web service is currently in a trial phase.'
      }
    },
    dataPage: {
      tabs: {
        discharge: 'Discharge',
        water_flow: 'Water runoff',
        temperature: 'Temperature',
        precipitation: 'Precipitation',
        evapotranspiration: 'Evaporation',
        groundwater: 'Groundwater',
        soil_water: 'Soil water'
      },
      describe: {
        climateChange: 'Climate change in',
        discharge: 'Mean discharge',
        water_flow: 'Mean water runoff',
        temperature: 'Mean temperature',
        precipitation: 'Mean precipitation',
        evapotranspiration: 'Mean evapotranspiration',
        groundwater: 'Mean groundwater',
        soil_water: 'Mean soil water',
        in: 'in',
        rcp45: 'Model values under the RCP4.5 moderate emissions scenario',
        rcp45Short: 'RCP4.5 moderate emissions scenario',
        rcp85: 'Model values under the high emissions scenario RCP8.5',
        rcp85Short: 'RCP8.5 high emissions scenario'
      },
      period: {
        period: 'Period',
        avg: 'Mean value',
        riverCycle: 'Annual cycle',
        map: 'On the map of the basin',
        selectPeriod: 'Select period',

        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',
        spring: 'Spring',
        summer: 'Summer',
        autumn: 'Autumn',
        winter: 'Winter',
        annual: 'Annual',

        back: 'Back'
      },
      compare: {
        compare: 'Compare',
        default: 'Compare with',
        norm: 'The norm for 1991-2020',
        null: 'Do not compare'
      },
      chart: {
        mod: 'Mod',
        median: 'Median',
        description1: 'Data source or basis for calculations',
        description2: '*Relative to the baseline period 1991-2020',
        description2end: "for the outlet of the basin",
        description2household: "for the outlet of the water household area",
        norm: 'Norm for 1991-2020',
        diapazonLegend: 'Range of deviations',
        nodata: 'No data for chart',
      },
      mapTable: {
        location: 'Location',
        forAnnual: 'annual',
        for: 'in',
        rcp85: 'under the high emissions scenario RCP8.5',
        rcp45: 'under the moderate emissions scenario RCP4.5'
      }
    },
    pageNotFinished: {
      title1: 'This page is currently under development.',
      title2: 'We will be back soon with new information!',
      goHome: 'Home page'
    },
    footer: {
      mainPage: 'Home page',
      holosary: 'Glossary',
      howPlatform: 'How to use the platform',
      dataSources: 'Data sources',
      otherPlatforms: 'Other useful platforms:'
    }
  },
  uk: {
    utils: {
      ukraine: 'Україна',
      pageNotFound: 'Сторінку не знайдено'
    },
    header: {
      menu: {
        is10Models: {
          name: 'Дані моделей клімату',
          options: {
            notIs10ModelsLabel: 'Групувати як мінімум, середнє і максимум',
            is10ModelsLabel: 'Показати дані по 10 моделям'
          }
        },
        amount: {
          name: 'Кількість',
          options: {
            variable: 'Зміна',
            absolute: 'Абсолютне значення, од. (не для всіх параметрів)'
          }
        },
        scenario: {
          name: 'Сценарій викидів в атмосферу',
          options: {
            SSP5: 'SSP5-8.5 (RCP8.5)— сценарій високих викидів',
            SSP2: 'SSP2-4.5 (RCP4.5) — сценарій помірних викидів'
          }
        },
        project: {
          name: 'Проєкт дослідження',
          options: {
            CMIP6: 'CMIP6',
            CORDEX: 'CORDEX-EUR-11'
          }
        },
        area: {
          name: 'Територія охоплення',
          options: {
            ukraine: 'У межах території України',
            transboundary: 'Із урахуванням транскордонної частини басейнів'
          }
        },
        precipitation: {
          name: 'Опади та температура',
          options: {
            reanalysis: 'Кліматичний реаналіз (ERA5-Land)',
            observation: 'Спостереження',
            observationWind: 'Спостереження із поправкою на вітер'
          }
        },
        evaporation: {
          name: 'Випаровування',
          options: {
            actual: 'Фактичне',
            potentially: 'Потенційне'
          }
        },
        glossary: {
          name: 'Глосарій'
        },
        howTo: {
          name: 'Як користуватись платформою'
        },
        reset: {
          name: 'Скинути до налаштувань за замовчуванням'
        },
        access: {
          name: 'Інструменти доступності',
          options: {
            textSize: 'Розмір тексту',
            cancel: 'Скасувати'
          }
        }
      },
      selectBasin: 'Виберіть басейн'
    },
    mainPage: {
      logoBlock: {
        describe: 'Портал даних про вплив змін клімату на водні ресурси України',
        goToData: 'Перейти до даних'
      },
      infoBlock: {
        growTemperature: 'Збільшилась температура за останні 15 років*',
        fallTemperature: 'Зменшилась температура за останні 15 років*',
        growPrecipitation: 'Збільшилась кількість опадів за останні 15 років*',
        fallPrecipitation: 'Зменшилась кількість опадів за останні 15 років*',
        growVolume: 'Збільшився об’єму стоку за останні 15 років*',
        fallVolume: 'Зменшився об’єму стоку за останні 15 років*'
      },
      description:
        '*Середнє значення за останні 15 років (2009-2023) відносно періоду 1991-2020 рр.',
      table: {
        riverBasin: 'Річка',
        theVolumeRunoff: "Об'єм <br /> стоку",
        temperature: 'Тем-ра, <br />',
        fall: 'Опади'
      },
      aboutPlatform: {
        title:
          '<span style="font-weight: bold;">Climate & Water (C & W)</span> - це зручний для користувача сервіс, який візуалізує результати моделювання впливу кліматичних змін на водні ресурси.',
        text1:
          'C & W містить дані про температуру повітря, опади, водний стік, випаровування, підземні води та вологість ґрунту. Алгоритми використовують гідрологічну модель України, історичні кліматичні дані та ансамбль останніх кліматичних моделей з високою роздільною здатністю.',
        text2:
          'Для зручності користувачів результати представлено на рівні річкових басейнів України та виділених водогосподарських ділянок. Користувачі можуть ознайомитися з річними, сезонними або місячними значеннями у форматі графіку або мапи. Всі дані є безкоштовними та доступні для завантаження.',
        attention: 'Вебсервіс перебуває у дослідній експлуатації.'
      }
    },
    dataPage: {
      tabs: {
        discharge: 'Витрати води',
        water_flow: 'Об’єм стоку',
        temperature: 'Температура',
        precipitation: 'Опади',
        evapotranspiration: 'Випаровування',
        groundwater: 'Підземні води',
        soil_water: 'Вологість ґрунту'
      },
      describe: {
        climateChange: 'Зміни клімату в',
        discharge: 'Середні витрати води',
        water_flow: "Середній об'єм стоку",
        temperature: 'Середня температура повітря',
        precipitation: 'Опади',
        evapotranspiration: 'Випаровування у',
        groundwater: 'Шар стоку ґрунтових вод',
        soil_water: 'Запас вологи ґрунту',
        in: 'у',
        rcp45: 'Значення моделей за сценарієм помірних викидів RCP4.5',
        rcp45Short: 'RCP4.5 - Сценарій помірних викидів',
        rcp85: 'Значення моделей за сценарієм високих викидів RCP8.5',
        rcp85Short: 'RCP8.5 - Сценарій високих викидів'
      },
      period: {
        period: 'Період',
        avg: 'Середнє значення',
        riverCycle: 'Річний цикл',
        map: 'На карті басейну',
        selectPeriod: 'Оберіть період',

        january: 'Січень',
        february: 'Лютий',
        march: 'Березень',
        april: 'Квітень',
        may: 'Травень',
        june: 'Червень',
        july: 'Липень',
        august: 'Серпень',
        september: 'Вересень',
        october: 'Жовтень',
        november: 'Листопад',
        december: 'Грудень',
        spring: 'Весна',
        summer: 'Літо',
        autumn: 'Осінь',
        winter: 'Зима',
        annual: 'Рік',
        back: 'Назад'
      },
      compare: {
        compare: 'Порівняти',
        default: 'Порівняти з',
        norm: 'Норма за 1991-2020 рр.',
        null: 'Не порівнювати'
      },
      chart: {
        mod: 'Мод',
        median: 'Медіана',
        description1: 'Джерело даних або на основі чого розрахунки',
        description2: '*Відносно базового періоду 1991-2020 рр.',
        description2end: 'для замикального створу басейну',
        description2household: "для замикального створу водогосподарської ділянки",
        norm: 'Норма за 1991-2020 рр.',
        diapazonLegend: 'Діапазон відхилень',
        nodata: 'Нема даних для графіка',
      },
      mapTable: {
        location: 'Ділянка',
        forAnnual: 'за рік',
        for: 'у',
        rcp85: 'за сценарієм високих викидів RCP8.5',
        rcp45: 'за сценарієм помірних викидів RCP4.5'
      }
    },
    pageNotFinished: {
      title1: 'Ця сторінка зараз в процесі розробки.',
      title2: 'Ми скоро повернемося з новою інформацією!',
      goHome: 'На головну'
    },
    footer: {
      mainPage: 'Головна сторінка',
      holosary: 'Глосарій',
      howPlatform: 'Як користуватись платформою',
      dataSources: 'Джерела даних',
      otherPlatforms: 'Інші корисні платформи:'
    }
  }
}

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
})

export default i18n
