import {computed, ref, watch} from 'vue'
import { defineStore } from 'pinia'

export const useMapSyncStore = defineStore('mapSync', () => {
  const firstMap = ref(null)
  const secondMap = ref(null)
  const syncing = ref(false) // Флаг для контроля синхронизации

  const synchronizeMapsZoom = (sourceMap, targetMap) => {
    if (!syncing.value) {
      syncing.value = true // Установка флага в true, блокирует повторную синхронизацию

      const zoom = sourceMap.getZoom()
      const center = sourceMap.getCenter()

      targetMap.setZoom(zoom)
      targetMap.setCenter(center)

      setTimeout(() => {
        syncing.value = false
      }, 0) // Сброс флага после выполнения синхронизации
    }
  }

  const synchronizeMapsMove = (sourceMap, targetMap) => {
    if (!syncing.value) {
      syncing.value = true // Установка флага в true, блокирует повторную синхронизацию

      const center = sourceMap.getCenter()

      targetMap.setCenter(center)

      setTimeout(() => {
        syncing.value = false
      }, 0) // Сброс флага после выполнения синхронизации
    }
  }

  const synchronizeMapsBearing = (sourceMap, targetMap) => {
    if (!syncing.value) {
      syncing.value = true // Установка флага в true, блокирует повторную синхронизацию

      const rotation = sourceMap.getBearing()

      targetMap.setBearing(rotation)

      setTimeout(() => {
        syncing.value = false
      }, 0) // Сброс флага после выполнения синхронизации
    }
  }

  const setupMapListeners = (map1, map2) => {
    map2.setCenter(map1.getCenter())
    map2.setZoom(map1.getZoom())
    map2.setBearing(map1.getBearing())

    map1.on('moveend', () => synchronizeMapsMove(map1, map2))
    map1.on('zoomend', () => synchronizeMapsZoom(map1, map2))
    map1.on('rotateend', () => synchronizeMapsBearing(map1, map2))

    map2.on('moveend', () => synchronizeMapsMove(map2, map1))
    map2.on('zoomend', () => synchronizeMapsZoom(map2, map1))
    map2.on('rotateend', () => synchronizeMapsBearing(map2, map1))
  }

  const removeMapListeners = (map1, map2) => {
    map1.on('moveend', () => {})
    map1.on('zoomend', () => {})
    map1.on('rotateend', () => {})

    map2.on('moveend', () => {})
    map2.on('zoomend', () => {})
    map2.on('rotateend', () => {})
  }

  const addMapForSync = (map) => {
    if (!firstMap.value) {
      firstMap.value = map
    } else if (!secondMap.value) {
      secondMap.value = map
      setupMapListeners(firstMap.value, secondMap.value)
    } else {
      throw new Error('Problem with addMapForSync')
    }
  }

  const removeMapForSync = () => {
    if (secondMap.value) {
      removeMapListeners(firstMap.value, secondMap.value)
      secondMap.value = null
      return
    }

    firstMap.value = null
  }

  const isCompareMap = computed(() => !!(firstMap.value && secondMap.value))

  return {
    addMapForSync,
    removeMapForSync,
    isCompareMap
  }
})
